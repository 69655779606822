var APP_DATA = {
  "scenes": [
    {
      "id": "0-driveway",
      "name": "Driveway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.4283989982167995,
        "pitch": -0.0025809567822907553,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.3428341088009716,
          "pitch": 0.0646743387434281,
          "rotation": 4.71238898038469,
          "target": "1-front-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-front-area",
      "name": "Front Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.334961122335967,
        "pitch": 0.03059045570932284,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.07617976725789433,
          "pitch": 0.13462511024786217,
          "rotation": 0,
          "target": "2-entrance"
        },
        {
          "yaw": -1.466789957967178,
          "pitch": 0.09953592323411797,
          "rotation": 0,
          "target": "0-driveway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-entrance",
      "name": "Entrance",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.5285632430238163,
        "pitch": -0.0012314010961151922,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.6021744352366802,
          "pitch": 0.31697905218850764,
          "rotation": 0,
          "target": "1-front-area"
        },
        {
          "yaw": 1.6781593590600217,
          "pitch": 0.1838725403046677,
          "rotation": 4.71238898038469,
          "target": "3-lounge"
        },
        {
          "yaw": 2.4578518471241706,
          "pitch": 0.2916638434436294,
          "rotation": 0,
          "target": "4-walkway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-lounge",
      "name": "Lounge",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 3.1361326248809878,
        "pitch": 0.14277880097717777,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.018240127693538,
          "pitch": 0.11972804079117694,
          "rotation": 1.5707963267948966,
          "target": "2-entrance"
        },
        {
          "yaw": 2.9618684985332386,
          "pitch": 0.12110803060977204,
          "rotation": 10.995574287564278,
          "target": "4-walkway"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-walkway",
      "name": "Walkway",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.817869257043867,
        "pitch": -0.010195271061116173,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.9118174326752495,
          "pitch": 0.24882640370210396,
          "rotation": 5.497787143782138,
          "target": "8-play-set"
        },
        {
          "yaw": -2.309540558051033,
          "pitch": 0.13528102763227068,
          "rotation": 0.7853981633974483,
          "target": "6-alora-series"
        },
        {
          "yaw": 2.602575785198561,
          "pitch": 0.07957505831901912,
          "rotation": 6.283185307179586,
          "target": "5-amani-series"
        },
        {
          "yaw": -0.1324844605732327,
          "pitch": 0.26338457610258814,
          "rotation": 0,
          "target": "2-entrance"
        },
        {
          "yaw": 0.5201844258146906,
          "pitch": 0.14147441097577307,
          "rotation": 1.5707963267948966,
          "target": "3-lounge"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-amani-series",
      "name": "Amani Series",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.08758369435171787,
        "pitch": -0.018061680783436174,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.0328792620029095,
          "pitch": 0.1339976807810661,
          "rotation": 5.497787143782138,
          "target": "5-amani-series"
        },
        {
          "yaw": 2.3033366866529903,
          "pitch": 0.0444582163235232,
          "rotation": 10.995574287564278,
          "target": "6-alora-series"
        }
      ],
      "infoHotspots": [
        {
          "yaw": 0.6418510591491273,
          "pitch": -0.022052250178353106,
          "title": "AMANI TANDEM",
          "text": "CHECK INSIDE",
          "link": "/amani-tandem/360"
        },
        {
          "yaw": -0.6578859574767968,
          "pitch": -0.027151770611766324,
          "title": "AMANI END",
          "text": "CHECK INSIDE",
          "link": "/amani-end/360"
        },
        {
          "yaw": -0.09466267408591023,
          "pitch": -0.028601636425278798,
          "title": "AMANI BARE UNIT",
          "text": "CHECK INSIDE",
          "link": "/amani-bare-unit/360"
        }
      ]
    },
    {
      "id": "6-alora-series",
      "name": "Alora Series",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.002855273650308021,
        "pitch": -0.01718236316240862,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.4960144575794292,
          "pitch": -0.00955791630649827,
          "rotation": 0,
          "target": "7-sales-office"
        },
        {
          "yaw": -2.5568142737619546,
          "pitch": 0.18970754920442623,
          "rotation": 0,
          "target": "8-play-set"
        },
        {
          "yaw": -2.260465118533663,
          "pitch": 0.0698401559007813,
          "rotation": 1.5707963267948966,
          "target": "5-amani-series"
        },
        {
          "yaw": -3.094169571324004,
          "pitch": 0.13884666197683515,
          "rotation": 0,
          "target": "4-walkway"
        },
        {
          "yaw": 2.803121862907502,
          "pitch": 0.09691643797657079,
          "rotation": 4.71238898038469,
          "target": "2-entrance"
        }
      ],
      "infoHotspots": [
        {
          "yaw": -0.1455350202451342,
          "pitch": -0.14261726668780028,
          "title": "ALORA UNIT",
          "text": "CHECK INSIDE",
          "link": "/alora/360"
        }
      ]
    },
    {
      "id": "7-sales-office",
      "name": "Sales Office",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.08377580409572971,
        "pitch": -0.27994210538761877,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.531615164413168,
          "pitch": 0.144510647382333,
          "rotation": 11.780972450961727,
          "target": "6-alora-series"
        },
        {
          "yaw": -1.9076691061898714,
          "pitch": 0.07936254618606498,
          "rotation": 5.497787143782138,
          "target": "5-amani-series"
        },
        {
          "yaw": -2.748055699508397,
          "pitch": 0.05400826090759381,
          "rotation": 0.7853981633974483,
          "target": "8-play-set"
        },
        {
          "yaw": 2.879654329422854,
          "pitch": 0.1458839723017782,
          "rotation": 5.497787143782138,
          "target": "2-entrance"
        }
      ],
      "infoHotspots": [
        {
          "yaw": -1.194114275713254,
          "pitch": -0.20316908939194533,
          "title": "ALORA UNIT",
          "text": "CHECK INSIDE"
        }
      ]
    },
    {
      "id": "8-play-set",
      "name": "Play Set",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.10186376179821366,
        "pitch": 0.26317285109415955,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.637290457628147,
          "pitch": 0.17149137636887168,
          "rotation": 0,
          "target": "6-alora-series"
        },
        {
          "yaw": -2.353596125980417,
          "pitch": 0.13406395366871315,
          "rotation": 5.497787143782138,
          "target": "2-entrance"
        },
        {
          "yaw": -2.051010050574071,
          "pitch": 0.09448208647726553,
          "rotation": 1.5707963267948966,
          "target": "3-lounge"
        },
        {
          "yaw": -0.9588240610253305,
          "pitch": 0.18404078823166614,
          "rotation": 1.5707963267948966,
          "target": "4-walkway"
        },
        {
          "yaw": -1.7361806759573746,
          "pitch": 0.31488463023678115,
          "rotation": 10.995574287564278,
          "target": "4-walkway"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360 Sights Exterior",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
