import react, { useState, useEffect } from "react";

// import '../css/privacy.css'

import '../css/privacy-v2.css'

import LoadScreen from "../../others/loadScreen";
import BackButton from "../../others/backButton";

const PrivacyPolicy = (props) => {

    const [isLoading, setIsLoading] = useState(true) 

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <>
        <LoadScreen isLoading={isLoading}/>
        <div className="content privacy">
            <div className="bg-color">
            <div id="main">
                <main className="">
                    <h2>Privacy Policy</h2>
                    <h4>This PRIVACY STATEMENT sets out how Century PHirst Corp., (the “Company”) uses your personal information and explains:</h4>
                    <h6>*Click to redirect:</h6>
                    <ul>
                        <li><a href="#sect1">What information do we obtain from you?</a></li>
                        <li><a href="#sect2">How is your information collected?</a></li>
                        <li><a href="#sect3">How is your information stored?</a></li>
                        <li><a href="#sect4">How does the Company use your information?</a></li>
                        <li><a href="#sect5">To whom will your information be disclosed?</a></li>
                        <li><a href="#sect6">How long do we retain your personal information?</a></li>
                        <li><a href="#sect7">Data Protection Officer</a></li>
                    </ul>

                    <div>
                        <p>This Policy applies to information acquired from the Company’s homebuyers and visitors of this social media accounts and/or website.</p>
                        <br />
                        <p>Information collected and held by the Company is subject to the Data Privacy Act of 2012, its Implementing Rules and Regulations (IRR), other issuances of National Privacy Commission (NPC), as well as other relevant laws of the Philippines. The Company is committed to keeping your information confidential and to comply with relevant laws, subject to the terms hereof.</p>
                    </div>
                </main>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect1"><i className="bi-question-circle"></i></a>
                        What information do we obtain from you?
                    </h5>
                    <p>The Company collects and holds your information such as, but not limited to, your name, address, contact number, and email address, and such other details accessible by the social media accounts and/or website.  </p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect2"><i className="bi-question-circle"></i></a>
                        How is your information collected?
                    </h5>
                    <p>Your information is collected whenever you complete forms while using the Company’s social media accounts and/or website.</p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect3"><i className="bi-question-circle"></i></a>
                        How is your information stored?
                    </h5>
                    <p>Your personal information is securely stored in our electronic database. The Company utilizes firewall protection for an additional layer of security.  It likewise uses antivirus and anti-malware software, and regularly updates virus definitions. Third parties who are hired to provide services and who have access to users’ information are required to implement privacy and security practices that the Company deems adequate.</p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect4"><i className="bi-question-circle"></i></a>
                        How does the Company use your information?
                    </h5>
                    <p>The information you provide will be used by the Company to provide the service you requested.  Primarily, the Company uses the information contained in the database primarily for service requests, dissemination of events, new product offerings, and similar activities held by the Company.  In addition, the Company utilizes the information held on the database for performing statistical analyses, executing marketing campaigns, and events or products development.</p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect5"><i className="bi-question-circle"></i></a>
                        To whom will your information be disclosed?
                    </h5>
                    <p>No other person, other than the Company’s Board of Directors, employees, agents, and contractors, are authorized to access your information held in the Company’s database.  Moreover, all persons who can access the information are required to maintain its confidentiality and comply with privacy laws.</p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect6"><i className="bi-question-circle"></i></a>
                        How long do we retain your personal information?
                    </h5>
                    <p>The Company reserves the right to retain your information in our database for a maximum retention period of five (5) years from date of collection, consistent with the provisions of the Data Privacy Act in lawful processing.  However, considering the fulfillment of the purpose and future reference, such information may still be retained if necessary. If the Company is required to retain any portion of your personal information for such purposes, it shall use reasonable efforts to limit such information to what is necessary to accomplish the particular purposes named above. Rest assured that the Company will maintain the confidentiality of all processed personal information.</p>
                </div>

                <div className="sect">
                    <h5>
                        <a href="#main" id="sect7"><i className="bi-question-circle"></i></a>
                        Data Protection Officer
                    </h5>
                    <p>To exercise your rights which include right to access, modify, erase, and object to processing your personal information within a reasonable time after such request or should you have any inquiries, feedbacks on this Privacy Policy, and/or complaints to the Company, you may reach our Data Protection Officer (DPO) through phone number: (02) 8424-2880 and/or email address: dpo.centuryphirst@gmail.com.</p>
                    <div className="img-privacy">
                        <img className="img-privacy-policy"
                        src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/privacy-policy-page/dpo-seal-cpc.png"></img>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

const PrivacyPolicyV2 = () => {

    const texturedBG = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/textured-bg-3.jpg'

    const collapseIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/collapse.png'
    const collapseIconAlt = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/collapse-alt.png'

    const [isLoading, setIsLoading] = useState(true)

    const [ isPolicy1, setPolicy1 ] = useState(false)
    const [ isPolicy2, setPolicy2 ] = useState(false)
    const [ isPolicy3, setPolicy3 ] = useState(false)
    const [ isPolicy4, setPolicy4 ] = useState(false)
    const [ isPolicy5, setPolicy5 ] = useState(false)
    const [ isPolicy6, setPolicy6 ] = useState(false)
    const [ isPolicy7, setPolicy7 ] = useState(false)

    useEffect(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        let policy = document.querySelector('#item1 .item-body')

        toggle(isPolicy1, policy)

    }, [isPolicy1])
    
    useEffect(() => {
        let policy = document.querySelector('#item2 .item-body')

        toggle(isPolicy2, policy)

    }, [isPolicy2])

    
    useEffect(() => {
        let policy = document.querySelector('#item3 .item-body')

        toggle(isPolicy3, policy)

    }, [isPolicy3])
    
    useEffect(() => {
        let policy = document.querySelector('#item4 .item-body')

        toggle(isPolicy4, policy)

    }, [isPolicy4])
    
    useEffect(() => {
        let policy = document.querySelector('#item5 .item-body')

        toggle(isPolicy5, policy)

    }, [isPolicy5])
    
    useEffect(() => {
        let policy = document.querySelector('#item6 .item-body')

        toggle(isPolicy6, policy)

    }, [isPolicy6])
    
    useEffect(() => {
        let policy = document.querySelector('#item7 .item-body')

        toggle(isPolicy7, policy)

    }, [isPolicy7])

    const toggle = (status, element) => {
        if (!status) {
            element.style.maxHeight = 0
        } else {
            element.style.maxHeight = element.scrollHeight + 'px'
        }
    }

    return (
        <>
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content policy-v2" style={{ backgroundImage: 'url('+ texturedBG +')'}}>
                <div className="policy-content">
                    <h2 className="privacy-title">Privacy Policy</h2>
                    <p className="privacy-desc">This PRIVACY STATEMENT sets out how Century PHirst Corp., (the “Company”) uses your personal information and explains:</p>

                    <div className="policy-collection">
                        <div className="policy-item collapsible-cont" id="item1">
                            <div className="item-header" onClick={ () => { setPolicy1((prevState) => !prevState) } }>
                                <h5>What information do we obtain from you?</h5>
                                <img src={ isPolicy1 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>The Company collects and holds your information such as, but not limited to, your name, address, contact number, and email address, and such other details accessible by the social media accounts and/or website.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item2">
                            <div className="item-header" onClick={ () => { setPolicy2((prevState) => !prevState) } }>
                                <h5>How is your information collected?</h5>
                                <img src={ isPolicy2 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>Your information is collected whenever you complete forms while using the Company’s social media accounts and/or website.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item3">
                            <div className="item-header" onClick={ () => { setPolicy3((prevState) => !prevState) } }>
                                <h5>How is your information stored?</h5>
                                <img src={ isPolicy3 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>Your personal information is securely stored in our electronic database. The Company utilizes firewall protection for an additional layer of security.  It likewise uses antivirus and anti-malware software, and regularly updates virus definitions. Third parties who are hired to provide services and who have access to users’ information are required to implement privacy and security practices that the Company deems adequate.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item4">
                            <div className="item-header" onClick={ () => { setPolicy4((prevState) => !prevState) } }>
                                <h5>How does the Company use your information?</h5>
                                <img src={ isPolicy4 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>The information you provide will be used by the Company to provide the service you requested.  Primarily, the Company uses the information contained in the database primarily for service requests, dissemination of events, new product offerings, and similar activities held by the Company.  In addition, the Company utilizes the information held on the database for performing statistical analyses, executing marketing campaigns, and events or products development.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item5">
                            <div className="item-header" onClick={ () => { setPolicy5((prevState) => !prevState) } }>
                                <h5>To whom will your information be disclosed?</h5>
                                <img src={ isPolicy5 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>No other person, other than the Company’s Board of Directors, employees, agents, and contractors, are authorized to access your information held in the Company’s database.  Moreover, all persons who can access the information are required to maintain its confidentiality and comply with privacy laws.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item6">
                            <div className="item-header" onClick={ () => { setPolicy6((prevState) => !prevState) } }>
                                <h5>How long do we retain your personal information?</h5>
                                <img src={ isPolicy6 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>The Company reserves the right to retain your information in our database for a maximum retention period of five (5) years from date of collection, consistent with the provisions of the Data Privacy Act in lawful processing.  However, considering the fulfillment of the purpose and future reference, such information may still be retained if necessary. If the Company is required to retain any portion of your personal information for such purposes, it shall use reasonable efforts to limit such information to what is necessary to accomplish the particular purposes named above. Rest assured that the Company will maintain the confidentiality of all processed personal information.</p>
                            </div>
                        </div>
                        <div className="policy-item collapsible-cont" id="item7">
                            <div className="item-header" onClick={ () => { setPolicy7((prevState) => !prevState) } }>
                                <h5>Data Protection Officer</h5>
                                <img src={ isPolicy7 ? collapseIconAlt : collapseIcon } alt=""/>
                            </div>
                            <div className={"item-body"}>
                                <p>To exercise your rights which include right to access, modify, erase, and object to processing your personal information within a reasonable time after such request or should you have any inquiries, feedbacks on this Privacy Policy, and/or complaints to the Company, you may reach our Data Protection Officer (DPO) through phone number: (02) 8424-2880 and/or email address: dpo.centuryphirst@gmail.com.</p>
                                <div className="img-privacy">
                                    <img className="img-privacy-policy"
                                    src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/privacy-policy-page/dpo-seal-cpc.png"></img>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="last-section">
                        <p>This Policy applies to information acquired from the Company’s homebuyers and visitors of this social media accounts and/or website.</p>
                        <br />
                        <p>Information collected and held by the Company is subject to the Data Privacy Act of 2012, its Implementing Rules and Regulations (IRR), other issuances of National Privacy Commission (NPC), as well as other relevant laws of the Philippines. The Company is committed to keeping your information confidential and to comply with relevant laws, subject to the terms hereof.</p>
                    </div>
                </div>
            </div>
        </>
    )
} 

export default PrivacyPolicyV2