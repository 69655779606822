import React, { useEffect, useState } from "react";

import '../css/amenity.css'

import LoadScreen from "../../others/loadScreen";

import BackButton from "../../others/backButton";

import { ContactFormTemplate } from "../../contact-us/components/contact-index";

const Amenities = () => {

    const amenityBg1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/play-set-bg-v2.jpg'
    const amenityMain1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/play-set-main.jpg'

    const amenityBg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/fitness-area-bg-v2.jpg'
    const amenityMain2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/fitness-area-main.jpg'

    const amenityBg3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/amani-pool-bg-v2.jpg'
    const amenityMain3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/amani-pool-main.jpg'

    const amenityBg4 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/amani-hall-bg-v2.jpg'
    const amenityMain4 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/amani-hall-main.jpg'

    const amenityBg5 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/alora-hall-bg-v2.jpg'
    const amenityMain5 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amenities/alora-hall-main.jpg'

    const [ isPlaySet, setPlaySet ] = useState(true)
    const [ isFitness, setFitness ] = useState(false)
    const [ isPool, setPool ] = useState(false)
    const [ isAmani, setAmani ] = useState(false)
    const [ isALora, setAlora ] = useState(false)

    const [isLoading, setIsLoading] = useState(true) 

    useEffect(() => {
        window.addEventListener('resize', resetScroll)
        setIsLoading(false)

        return () => {   
            window.removeEventListener('resize', resetScroll)
        }
    }, [])

    const view = (val) => {
        switch (val) {
            case 'amenity1':
                setPlaySet(true)
                setFitness(false)
                setPool(false)
                setAmani(false)
                setAlora(false)
                break;
            case 'amenity2':
                setPlaySet(false)
                setFitness(true)
                setPool(false)
                setAmani(false)
                setAlora(false)
                break;
            case 'amenity3':
                setPlaySet(false)
                setFitness(false)
                setPool(true)
                setAmani(false)
                setAlora(false)
                break;
            case 'amenity4':
                setPlaySet(false)
                setFitness(false)
                setPool(false)
                setAmani(true)
                setAlora(false)
                break;
            case 'amenity5':
                setPlaySet(false)
                setFitness(false)
                setPool(false)
                setAmani(false)
                setAlora(true)
                break;
            default:
                break;
        }
        const amenityItem = document.getElementById(val)
        amenityItem.scrollIntoView()
    }

    const resetScroll = () => {
        let cont = document.getElementById('amenityCont')
        cont.scrollLeft = 0
        
        setPlaySet(true)
        setFitness(false)
        setPool(false)
        setAmani(false)
        setAlora(false)

        let selectAmenity = document.getElementById('selectAmenity')
        if (selectAmenity) {
            selectAmenity.selectedIndex = 0
        }
    }

    return (
        <>
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content amenities">
                <div className="amenity-control">

                    <button 
                        className={ "btn-control " + ( isPlaySet ? 'active' : '' ) } 
                        onClick={ () => { view('amenity1') } } >
                            Play Set
                    </button>
                    <button 
                        className={ "btn-control " + ( isFitness ? 'active' : '' ) } 
                        onClick={ () => { view('amenity2') } } >
                            Fitness Equipment
                    </button>
                    <button 
                        className={ "btn-control " + ( isPool ? 'active' : '' ) } 
                        onClick={ () => { view('amenity3') } } >
                            Swimming Pool
                    </button>
                    <button 
                        className={ "btn-control " + ( isAmani ? 'active' : '' ) } 
                        onClick={ () => { view('amenity4') } } >
                            Function Hall
                    </button>
                    {/* <button 
                        className={ "btn-control " + ( isALora ? 'active' : '' ) } 
                        onClick={ () => { view('amenity5') } } >
                            Alora
                    </button> */}

                </div>
                <div className="amenity-control-mobile">
                    <select id="selectAmenity" onChange={ (e) => { view(e.target.value) } }>
                        <option value="amenity1">Play Set</option>
                        <option value="amenity2">Fitness Equipment</option>
                        <option value="amenity3">Swimming Pool</option>
                        <option value="amenity4">Function Hall</option>
                        {/* <option value="amenity5">Alora</option> */}
                    </select>
                </div>
                <div className="amenities-cont" id="amenityCont">
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg1 +')'}} id="amenity1">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Play Set</h4>
                            <img className="amenity-img" src={ amenityMain1 } alt="" />
                            <p className="amenity-desc">Nurture your kids to a life full of adventures! Let them play to their heart’s content while socializing with other kids in the neighborhood.</p>
                        </div>
                        <div className="amenity-img-content">
                            <img className="amenity-img" src={ amenityMain1 } alt="" />
                        </div>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg2 +')'}} id="amenity2">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Fitness Equipment</h4>
                            <img className="amenity-img" src={ amenityMain2 } alt="" />
                            <p className="amenity-desc">You no longer have to go far to enjoy the active and fit life with PHirst Sights. Increase your stamina, burn those calories, and build muscles with PHirst Sights’ Fitness Equipment.</p>
                        </div>
                        <div className="amenity-img-content">
                            <img className="amenity-img" src={ amenityMain2 } alt="" />
                        </div>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg3 +')'}} id="amenity3">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Swimming Pool</h4>
                            <span>(Amani Only)</span>
                            <img className="amenity-img" src={ amenityMain3 } alt="" />
                            <p className="amenity-desc">Cool off, relax, and enjoy at PHirst Sights Amani’s Swimming Pool. You’re not just getting a workout, you’re also resting from the stresses of work and daily life.</p>
                        </div>
                        <div className="amenity-img-content">
                            <img className="amenity-img" src={ amenityMain3 } alt="" />
                        </div>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg5 +')'}} id="amenity4">
                        <div className="amenity-text-content amenity-amani show">
                            <h4 className="amenity-title">Amani Function Hall</h4>
                            <img className="amenity-img-hall" src={ amenityMain4 } alt="" />
                            <p className="amenity-desc">Need a place to host events and celebrations? PHirst Sights’ Amani’s Function Hall lets you enjoy the spacious and comfortable setting so you and your neighbors can meet and build a happy community together.</p>
                        </div>

                        <div className="amenity-text-content amenity-alora">
                            <h4 className="amenity-title">Alora Function Hall</h4>
                            <img className="amenity-img-hall" src={ amenityMain5 } alt="" />
                            <p className="amenity-desc">Get to know your neighbors with PHirst Sights Alora’s Function Hall! Come together, celebrate, and make memories, while creating opportunities for recreation and community services. It’s a place where everyone can feel welcome, and where a sense of community can grow!</p>
                        </div>
                        {/* <div className="amenity-img-content amenity-alora">
                            <img className="amenity-img" src={ amenityMain5 } alt="" />
                        </div> */}
                    </div>
                    {/* <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg5 +')'}} id="amenity5">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Alora Function Hall</h4>
                            <img className="amenity-img" src={ amenityMain5 } alt="" />
                            <p className="amenity-desc">Get to know your neighbors with PHirst Sights Alora’s Function Hall! Come together, celebrate, and make memories, while creating opportunities for recreation and community services. It’s a place where everyone can feel welcome, and where a sense of community can grow!</p>
                        </div>
                        <div className="amenity-img-content">
                            <img className="amenity-img" src={ amenityMain5 } alt="" />
                        </div>
                    </div> */}
                </div>

                <div className="amenities-cont-mobile" id="amenityContMobile">
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg1 +')'}} id="amenity1">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Play Set</h4>
                            <img className="amenity-img" src={ amenityMain1 } alt="" />
                            <p className="amenity-desc">Nurture your kids to a life full of adventures! Let them play to their heart’s content while socializing with other kids in the neighborhood.</p>
                        </div>
                            <small className="scroll-down">
                                Scroll down <br /> to see more
                            </small>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg2 +')'}} id="amenity2">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Fitness Area</h4>
                            <img className="amenity-img" src={ amenityMain2 } alt="" />
                            <p className="amenity-desc">You no longer have to go far to enjoy the active and fit life with PHirst Sights. Increase your stamina, burn those calories, and build muscles with PHirst Sights’ Fitness Area.</p>
                        </div>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg3 +')'}} id="amenity3">
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Swimming Pool</h4>
                            <span>(Amani Only)</span>
                            <img className="amenity-img" src={ amenityMain3 } alt="" />
                            <p className="amenity-desc">Cool off, relax, and enjoy at PHirst Sights Amani’s Swimming Pool. You’re not just getting a workout, you’re also resting from the stresses of work and daily life.</p>
                        </div>
                    </div>
                    <div className="amenity-item" style={{ backgroundImage: 'url('+ amenityBg4 +')'}} id="amenity4"> 
                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Amani Function Hall</h4>
                            <img className="amenity-img-hall" src={ amenityMain4 } alt="" />
                            <p className="amenity-desc">Need a place to host events and celebrations? PHirst Sights’ Amani’s Function Hall lets you enjoy the spacious and comfortable setting so you and your neighbors can meet and build a happy community together.</p>
                        </div>

                        <div className="amenity-text-content">
                            <h4 className="amenity-title">Alora Function Hall</h4>
                            <img className="amenity-img-hall" src={ amenityMain5 } alt="" />
                            <p className="amenity-desc">Get to know your neighbors with PHirst Sights Alora’s Function Hall! Come together, celebrate, and make memories, while creating opportunities for recreation and community services. It’s a place where everyone can feel welcome, and where a sense of community can grow!</p>
                        </div>
                    </div>
                </div>

                <ContactFormTemplate/>
            </div>
        </>
    )
}

export default Amenities