var APP_DATA = {
  "scenes": [
    {
      "id": "0-living-room-and-dining-area",
      "name": "Living Room and Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.098038456017571,
        "pitch": 0.09737247992416798,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.142433912679853,
          "pitch": 0.09778405603034557,
          "rotation": 0,
          "target": "1-kitchen"
        },
        {
          "yaw": 3.049546689142785,
          "pitch": 0.09418170507981927,
          "rotation": 0,
          "target": "6-back-exterior"
        },
        {
          "yaw": -2.874871952904387,
          "pitch": 0.08771254410396345,
          "rotation": 0,
          "target": "2-bathroom"
        },
        {
          "yaw": -2.6746844283474527,
          "pitch": -0.33839842076189974,
          "rotation": 0.7853981633974483,
          "target": "3-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-kitchen",
      "name": "Kitchen",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.2746623676997793,
        "pitch": -0.16268538210727357,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.6274453079225779,
          "pitch": 0.06866087540218757,
          "rotation": 0,
          "target": "0-living-room-and-dining-area"
        },
        {
          "yaw": -1.8263871151658293,
          "pitch": -0.1863514020119048,
          "rotation": 0.7853981633974483,
          "target": "3-upstairs"
        },
        {
          "yaw": -2.118759182951404,
          "pitch": -0.06214789419626676,
          "rotation": 4.71238898038469,
          "target": "2-bathroom"
        },
        {
          "yaw": -2.282656322522909,
          "pitch": -0.26564024645912276,
          "rotation": 4.71238898038469,
          "target": "6-back-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.9320009993544627,
        "pitch": -0.014485578208992322,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.064912920474523,
          "pitch": -0.11192089751229872,
          "rotation": 0,
          "target": "3-upstairs"
        },
        {
          "yaw": 2.371679153402315,
          "pitch": 0.0940268952738954,
          "rotation": 0,
          "target": "0-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.255209209580199,
        "pitch": 0.036718310285097644,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.590429017355973,
          "pitch": 0.25472847003738863,
          "rotation": 0,
          "target": "1-kitchen"
        },
        {
          "yaw": -1.0743901992433855,
          "pitch": 0.4003983192650278,
          "rotation": 1.5707963267948966,
          "target": "6-back-exterior"
        },
        {
          "yaw": -0.681232342716676,
          "pitch": 0.4986029898836932,
          "rotation": 1.5707963267948966,
          "target": "2-bathroom"
        },
        {
          "yaw": -3.067969375641514,
          "pitch": -0.33985964831721915,
          "rotation": 0,
          "target": "4-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-bedroom",
      "name": "Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.5494743676056153,
        "pitch": 0.2457484946940074,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.3856436723045693,
          "pitch": 0.1975080544309371,
          "rotation": 0,
          "target": "5-2nd-floor-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-2nd-floor-view",
      "name": "2nd Floor View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.7910420827298985,
        "pitch": 0.26585733065392425,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.10110701748296,
          "pitch": 0.29327529940523434,
          "rotation": 10.210176124166829,
          "target": "3-upstairs"
        },
        {
          "yaw": 1.482420962732668,
          "pitch": 0.7116036647472015,
          "rotation": 5.497787143782138,
          "target": "2-bathroom"
        },
        {
          "yaw": 1.1756866064757432,
          "pitch": 0.7742830050544534,
          "rotation": 5.497787143782138,
          "target": "6-back-exterior"
        },
        {
          "yaw": 1.070606282955012,
          "pitch": 1.221229001646483,
          "rotation": 11.780972450961727,
          "target": "1-kitchen"
        },
        {
          "yaw": 2.916229415423512,
          "pitch": 0.5458125392983177,
          "rotation": 0,
          "target": "4-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-back-exterior",
      "name": "Back Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.7590592390911333,
        "pitch": 0.08016123482839177,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.406139783161122,
          "pitch": 0.08411265873290752,
          "rotation": 0,
          "target": "0-living-room-and-dining-area"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-Alora",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
