import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './partials/navbar';

// Pages
import Landing from './pages/LandingPage/components/main';
import AmaniSeries from './pages/amani-series/components/amani-index'
import AloraSeries from './pages/alora-series/components/alora-index';
import AboutUs from './pages/about-us/components/about-index';
import ContactUs from './pages/contact-us/components/contact-index';
import PrivacyPolicyV2 from './pages/privacy-policy/components/privacyPolicy';

import MarketingTent from './pages/marketing-tent/tent-index';
import Amenities from './pages/amenities/components/amenity-index';
import ExplorePage from './pages/explore/components/explore-index';

import VicinityMap from './pages/vicinity-map/components/map-index';

// 360
import Alora360 from './pages/360/alora/components/index'
import AmaniBareUnit360 from './pages/360/amani-bare-unit/components/index'
import AmaniEnd360 from './pages/360/amani-end/components/index'
import AmaniInner360 from './pages/360/amani-inner/components/index'
import AmaniPlus360 from './pages/360/amani-plus/components/index'
import AmaniTandem360 from './pages/360/amani-tandem/components/index'
import SightsExit360 from './pages/360/sights-exit/components/index'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Navbar/>
      <Routes>
          <Route path='/' element={ <Landing /> } />
          <Route path='/about' element={ <AboutUs /> } />
          <Route path='amani-series' element={ <AmaniSeries /> } />
          <Route path='alora-series' element={ <AloraSeries /> } />
          <Route path='contact-us' element={ <ContactUs /> } />
          <Route path='privacy-policy' element={ <PrivacyPolicyV2 /> } />
          <Route path='marketing-tent' element={ <MarketingTent /> } />
          <Route path='amenities' element={ <Amenities /> }/>
          <Route path='explore-sights' element={<ExplorePage/>} />
          
          <Route path='vicinity-map' element={<VicinityMap/>} />


          <Route path='/alora/360' element={ <Alora360 />}/>
          <Route path='/amani-bare-unit/360' element={ <AmaniBareUnit360 />}/>
          <Route path='/amani-end/360' element={ <AmaniEnd360 />}/>
          <Route path='/amani-inner/360' element={ <AmaniInner360 />}/>
          <Route path='/amani-plus/360' element={ <AmaniPlus360 />}/>
          <Route path='/amani-tandem/360' element={ <AmaniTandem360 />}/>
          <Route path='/sights-exit/360' element={ <SightsExit360 />}/>

      </Routes>
    </BrowserRouter>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
