var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.900631285236642,
        "pitch": 0.042012189342422346,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.3277540056815784,
          "pitch": -0.04780006468781828,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.865125705990118,
        "pitch": 0.18952373906167885,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.862016406450177,
          "pitch": 0.02720708325265342,
          "rotation": 0,
          "target": "3-back-exterior"
        },
        {
          "yaw": -3.0110397425226445,
          "pitch": 0.030624756290373867,
          "rotation": 0,
          "target": "2-bathroom-1st-floor"
        },
        {
          "yaw": -2.587238827861267,
          "pitch": -0.018510251054189908,
          "rotation": 7.0685834705770345,
          "target": "4-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 3.1405208137219347,
        "pitch": 0.13464108751873027,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.159060595069561,
          "pitch": 0.22407583421081867,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-back-exterior",
      "name": "Back Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.04855073543767574,
        "pitch": -0.0038103280620251923,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.209060595069561,
          "pitch": 0.22407583421081867,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.27866460098463,
        "pitch": -0.061577997358453374,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.1357311756908075,
          "pitch": -0.40771596338592,
          "rotation": 1.5707963267948966,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": -2.359060595069561,
          "pitch": 0.22407583421081867,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -1.424956079483911,
          "pitch": 0.23645375481631348,
          "rotation": 1.5707963267948966,
          "target": "2-bathroom-1st-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.89777449149219,
        "pitch": 0.20237980588276372,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.890603721801714,
          "pitch": 0.7142224870916998,
          "rotation": 4.71238898038469,
          "target": "4-upstairs"
        },
        {
          "yaw": 3.0904290049093124,
          "pitch": 0.05683363177080736,
          "rotation": 0,
          "target": "7-bathroom-2nd-floor"
        },
        {
          "yaw": 0.34639065077036335,
          "pitch": 0.2577787919403036,
          "rotation": 0,
          "target": "6-room-1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-room-1",
      "name": "Room #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.17805988617955038,
        "pitch": 0.013626161906939771,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.8347527301692033,
          "pitch": 0.45620629830509785,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": 2.5950024462807253,
          "pitch": 0.02180921956107973,
          "rotation": 0,
          "target": "7-bathroom-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.7877892705305065,
        "pitch": 0.08185228803706757,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.0347527301692033,
          "pitch": 0.25620629830509785,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-amari-bare-unit",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
