import React, { useState, useEffect} from "react";

import '../css/explore.css'

import LoadScreen from "../../others/loadScreen";
import BackButton from "../../others/backButton";

const ExplorePage = () => {
    const mainBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/explore-main-bg.jpg'
    const titleStep1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/1-title-reservation.png'
    const titleStep2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/2-title-booking.png'
    const titleStep3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/3-title-completion.png'
    const titleStep4 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/4-title-house-completion.png'
    const titleStep5 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/5-title-house-turnover.png'
    
    const exploreBG = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/explore-lattice-bg.jpg'

    const iconTransfer = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-transfer.png'
    const icon5Percent = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-5percent.png'
    const icon10Percent = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-10percent.png'
    const icon100Percent = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-100percent.png'
    const iconDocsCheck = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-docs-check.png'
    const iconHousePercent = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-house-percent.png'
    const iconHouseMoney = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-house-money.png'
    const iconHouseHammer = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-house-hammer.png'
    const iconHouseCheck = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/icons/icon-house-check.png'

    const leftArrow = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/left-arrow-yellow.png'
    const rightArrow = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/right-arrow-yellow.png'
    
    const texturedBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/textured-bg-3.jpg'

    const centuryProperties = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/Century-Properties.jpg"

    const [isLoading, setIsLoading] = useState(true) 

    const scroll = (val) => {
        let stepsContainer = document.getElementById('stepsContBody')
        
        if (val === 'left') {
            stepsContainer.scrollLeft = stepsContainer.scrollLeft - stepsContainer.offsetWidth 
        } else if (val === 'right') {
            stepsContainer.scrollLeft = stepsContainer.scrollLeft + stepsContainer.offsetWidth 
        }
    }

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <>
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content explore">
                <main className="main" style={{ backgroundImage: 'url('+ mainBg +')' }}>
                    <div className="text-content">
                        <h2><span>Explore</span>Sights</h2>
                        <p>Experience an easy <span>Access</span> on anything about PHirst Sights! From getting the latest updates into buying a home, your journey is productive and worry-free.</p>
                        
                        <div>
                            <a href="#buyingGuide">Check out Buying Steps</a>
                            <a href="#newsCont">Read our News & Articles</a>
                        </div>
                    </div>
                </main>

                <div className="buying-guide" id="buyingGuide">
                    <h4><em>5</em> Steps to <em>Access</em> your PHirst Home</h4>
                    
                    <div className="steps-cont" id="stepsCont">
                        <div className="body" id="stepsContBody">
                            <div className="step-item" id="step1">
                                <img className="step-title" src={ titleStep1 } alt="" />
                                <div className="guide-container">

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                BUYER
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Day<span>1</span></h5>
                                            <ul className="item-list">
                                                <li>Pays reservation fee</li>
                                                <li>Brings 1 valid ID</li>
                                                <li>Completes Reservation Documents</li>
                                            </ul>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Day<span>20</span></h5>
                                            <ul className="item-list">
                                                <li>Pays reservation fee</li>
                                                <li>Brings 1 valid ID</li>
                                                <li>Completes Reservation Documents</li>
                                            </ul>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Day<span>30</span></h5>
                                            <ul className="item-list">
                                                <li>Pays reservation fee</li>
                                                <li>Brings 1 valid ID</li>
                                                <li>Completes Reservation Documents</li>
                                            </ul>
                                        </div>
                                    </div>
                                        <img className="icon-transfer" src={ iconTransfer } alt="" />

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                PHirst
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>2</span></h5>
                                            <ul className="item-list">
                                                <li>Conducts pre-qualification</li>
                                            </ul>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>3-5</span></h5>
                                            <ul className="item-list">
                                                <li>Assists in home loan application to financial institution <br />
                                                    (Submits Buyer's Booking Docs on behalf of buyer for financial assessment)
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="step-item" id="step2">
                                <img className="step-title" src={ titleStep2 } alt="" />
                                <div className="guide-container">

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                BUYER
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>6</span></h5>
                                            <ul className="item-list">
                                                <li>Signs Letter of Guarantee issued by financial institution</li>
                                            </ul>
                                            <ul className="item-list">
                                                <li>Reaches 5% downpayment* <br /><em>Once buyer submitted complete documents</em></li>
                                            </ul>
                                            <img className="icon-5percent" src={ icon5Percent } alt="" />
                                        </div>
                                    </div>

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                PHirst
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>6</span></h5>
                                            <ul className="item-list">
                                                <li>Secures Letter of Approval for qualified buyers <br /></li>
                                            </ul>
                                            <img className="icon-docs-check" src={ iconDocsCheck } alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="step-item" id="step3">
                                <img className="step-title" src={ titleStep3 } alt="" />
                                <div className="guide-container">

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                BUYER
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>12</span></h5>
                                            <ul className="item-list">
                                                <li>Reaches full downpayment* <br />
                                                <em>*submits latest income docs</em></li>
                                            </ul>
                                            <img className="icon-10percent" src={ icon10Percent } alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="step-item" id="step4">
                                <img className="step-title" src={ titleStep4 } alt="" />
                                <div className="guide-container">

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                BUYER
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>13</span></h5>
                                            <ul className="item-list">
                                                <li>Starts home loan amortization to financial institution <br />(30 days after Loan Takeout)</li>
                                            </ul>
                                            <img className="icon-house-percent" src={ iconHousePercent } alt="" />
                                        </div>
                                    </div>

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                PHirst
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>13</span></h5>
                                            <ul className="item-list">
                                                <li>Takes out home loan</li>
                                            </ul>
                                            <img className="icon-house-money" src={ iconHouseMoney } alt="" />
                                            <img className="icon-100percent" src={ icon100Percent } alt="" />
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>13-18</span></h5>
                                            <ul className="item-list">
                                                <li>House Construction**</li>
                                            </ul>
                                            <img className="icon-house-hammer" src={ iconHouseHammer } alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="step-item" id="step5">
                                <img className="step-title" src={ titleStep5 } alt="" />
                                <div className="guide-container">

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                BUYER
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>13</span></h5>
                                            <ul className="item-list">
                                                <li>Inspects house</li>
                                                <li>Signs acceptance documents</li>
                                            </ul>
                                            <img className="icon-docs-check" src={ iconDocsCheck } alt="" />
                                        </div>
                                    </div>

                                    <div className="guide">
                                        <div className="step-sub-title-cont">
                                            <h5 className="sub-title">
                                                PHirst
                                            </h5>
                                            <span className="color-bar"></span>
                                        </div>
                                        <div className="guide-item">
                                            <h5 className="item-title">Month<span>13</span></h5>
                                            <ul className="item-list">
                                                <li>Sends notice of turnover</li>
                                            </ul>
                                            <img className="icon-house-check" src={ iconHouseCheck } alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="scroll-button-cont">
                            <img className="prev-btn" src={leftArrow} alt="" onClick={ () => {scroll('left')} }/>
                            <img className="next-btn" src={rightArrow} alt="" onClick={ () => {scroll('right')} }/>
                        </div>
                    </div>

                    <div className="steps-cont-mobile">
                        <div className="step-item" id="step1">
                            <img className="step-title" src={ titleStep1 } alt="" />
                            <div className="guide-container">

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            BUYER
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Day<span>1</span></h5>
                                        <ul className="item-list">
                                            <li>Pays reservation fee</li>
                                            <li>Brings 1 valid ID</li>
                                            <li>Completes Reservation Documents</li>
                                        </ul>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Day<span>20</span></h5>
                                        <ul className="item-list">
                                            <li>Pays reservation fee</li>
                                            <li>Brings 1 valid ID</li>
                                            <li>Completes Reservation Documents</li>
                                        </ul>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Day<span>30</span></h5>
                                        <ul className="item-list">
                                            <li>Pays reservation fee</li>
                                            <li>Brings 1 valid ID</li>
                                            <li>Completes Reservation Documents</li>
                                        </ul>
                                    </div>
                                </div>
                                    {/* <img className="icon-transfer" src={ iconTransfer } alt="" /> */}

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            PHirst
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>2</span></h5>
                                        <ul className="item-list">
                                            <li>Conducts pre-qualification</li>
                                        </ul>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>3-5</span></h5>
                                        <ul className="item-list">
                                            <li>Assists in home loan application to financial institution <br />
                                                (Submits Buyer's Booking Docs on behalf of buyer for financial assessment)
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="step-item" id="step2">
                            <img className="step-title" src={ titleStep2 } alt="" />
                            <div className="guide-container">

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            BUYER
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>6</span></h5>
                                        <ul className="item-list">
                                            <li>Signs Letter of Guarantee issued by financial institution</li>
                                        </ul>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>6</span></h5>
                                        <ul className="item-list">
                                            <li>Reaches 5% downpayment* <br /><em>Once buyer submitted complete documents</em></li>
                                        </ul>
                                        <img className="icon-5percent" src={ icon5Percent } alt="" />
                                    </div>
                                </div>

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            PHirst
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>6</span></h5>
                                        <ul className="item-list">
                                            <li>Secures Letter of Approval for qualified buyers <br /></li>
                                        </ul>
                                        <img className="icon-docs-check" src={ iconDocsCheck } alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="step-item" id="step3">
                            <img className="step-title" src={ titleStep3 } alt="" />
                            <div className="guide-container">

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            BUYER
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>12</span></h5>
                                        <ul className="item-list">
                                            <li>Reaches full downpayment* <br />
                                            <em>*submits latest income docs</em></li>
                                        </ul>
                                        <img className="icon-10percent" src={ icon10Percent } alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="step-item" id="step4">
                            <img className="step-title" src={ titleStep4 } alt="" />
                            <div className="guide-container">

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            BUYER
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>13</span></h5>
                                        <ul className="item-list">
                                            <li>Starts home loan amortization to financial institution <br />(30 days after Loan Takeout)</li>
                                        </ul>
                                        <img className="icon-house-percent" src={ iconHousePercent } alt="" />
                                    </div>
                                </div>

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            PHirst
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>13</span></h5>
                                        <ul className="item-list">
                                            <li>Takes out home loan</li>
                                        </ul>
                                        <img className="icon-house-money" src={ iconHouseMoney } alt="" />
                                        <img className="icon-100percent" src={ icon100Percent } alt="" />
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>13-18</span></h5>
                                        <ul className="item-list">
                                            <li>House Construction**</li>
                                        </ul>
                                        <img className="icon-house-hammer" src={ iconHouseHammer } alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="step-item" id="step5">
                            <img className="step-title" src={ titleStep5 } alt="" />
                            <div className="guide-container">

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            BUYER
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>13</span></h5>
                                        <ul className="item-list">
                                            <li>Inspects house</li>
                                            <li>Signs acceptance documents</li>
                                        </ul>
                                        <img className="icon-docs-check" src={ iconDocsCheck } alt="" />
                                    </div>
                                </div>

                                <div className="guide">
                                    <div className="step-sub-title-cont">
                                        <h5 className="sub-title">
                                            PHirst
                                        </h5>
                                        <span className="color-bar"></span>
                                    </div>
                                    <div className="guide-item">
                                        <h5 className="item-title">Month<span>13</span></h5>
                                        <ul className="item-list">
                                            <li>Sends notice of turnover</li>
                                        </ul>
                                        <img className="icon-house-check" src={ iconHouseCheck } alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <small className="disclaimer">** Disclaimer **     All periods indicated are estimated timelines for the general guidance of the Buyer. PHirst / Sales Agents and brokers will provide hand in hand assistance to the Buyer throughout the process.</small>

                <div className="news" style={{ backgroundImage: 'url('+ texturedBg +')'}} id="newsCont">
                    <div className="news-header">
                        <h2 className="title">News & Blogs</h2>
                        <p className="desc">Get the latest news, updates, and informative articles about PHirst Sights and economical housing.</p>
                    </div>
                    <div className="article">
                        <h4>Century Properties enters affordable housing market</h4>
                        <small>February 24, 2023 | 12:06 am</small>
                        <img src={centuryProperties} alt="" />
                        <div className="article-content">
                            <p>CENTURY Properties Group, Inc. is set to expand into the mid-income residential market through its new subsidiary Century PHirst Corp.</p>
                            <p>In a press release on Thursday, the property developer said that its subsidiary PHirst Park Homes, Inc., which is mainly focused on first-time homeowners, will venture into the socialized, economic, and mid-income residential markets.</p>
                            <p>“The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines. With this strategic initiative, we are poised to bring more quality homes to the market, that are accessible to a wider range of Filipinos,” said Jose Marco R. Antonio, president and chief executive officer of Century Properties.</p>
                            <p>Century PHirst will oversee three flagship projects in Laguna, Batangas, and Bataan provinces.</p>
                            <p>“These expansion efforts bring forth a broader range of housing packages and price points to provide first-time home buyers with a wider set of options to acquire their very own PHirst home,” Century Properties said.</p>
                            <p>PHirst Editions Batulao — the 14.1-hectare development project in Batangas with an estimated P3.06 billion in total sales value — will provide 629 single attached and single detached units in the area.</p>
                            <p>The floor areas of the house model range from 54 to 120 square meters (sq.m.), and typical lot areas range from 88 to 132 sq.m. The homes are priced at P3.2 million to P6 million.</p>
                            <p>Additionally, the company has introduced a P2.3-billion housing project in Bay, Laguna spanning 15.3 hectares and offering 1,818 units. The project is set to be launched on March 18.</p>
                            <p>PHirst Sights Bay will offer socialized and economic housing with two house models ranging from 30 to 36 sq.m. with a typical lot area of 40 to 56 sq.m. The socialized housing will be priced at P580,000 while economic homes will range from P800,000 to P1.5 million.</p>
                            <p>Meanwhile, the company targets to launch its first mixed-use township in Bataan. Valued at P6.47 billion, the project will cover 36 hectares and will offer 2,041 units.</p>
                            <p>“PHirst Centrale Hermosa has become the latest ‘it’ town where individuals convene to reside, innovate, and flourish based on the township’s three fundamental principles: Dwell, Create & Thrive,” the company said.</p>
                            <p>PHirst Centrale will include residential, commercial, and retail establishments, it added.</p>
                            <p>“Residents can lead a stylish lifestyle, revel in various breathtaking activities and livelihood opportunities, and cultivate their social networks, enhancing their quality of life with more ease and excitement,” it said.</p>
                            <p>The P3.1-billion residential area of the project will cover 14.5 hectares with 1,574 units at prices ranging from P1.7 million to P2.5 million.</p>
                            <p>The commercial development project will cover 3.1 hectares valued at P602 million and will have 21 units available for sale ranging from 503 to 1,531 sq.m. per lot.</p>
                            <p>According to Century Properties, PHirst has so far built 5,908 units and turned over 3,835 houses to its clients.</p>
                            <p>On Thursday, Century Properties shares declined by 1.37% or P0.005, finishing at P0.36 each at the stock exchange.</p>
                            
                            <h5>- Adrian H. Halili</h5>
                            <a href="https://www.bworldonline.com/corporate/2023/02/24/506676/century-properties-enters-affordable-housing-market/" target={'_blank'}>Read the original article here.</a>
                        </div>
                    </div>
                    <div className="article">
                        <h4>Century Properties ventures into new housing segments</h4>
                        <small>February 24, 2023 | 12:00 am</small>
                        {/* <img src={centuryProperties} alt="" /> */}
                        <div className="article-content">
                            <p>MANILA, Philippines — Century Properties Group Inc. (CPGI), the listed property company of the Antonio Group, is forming a new subsidiary Century PHirst Corp. (PHirst) as it expands its product offerings to tap other segments in the market.</p>
                            <p>PHirst will underscore three flagship projects, a mid-income development, a socialized and economic housing project and a mixed-use development in Bataan.</p>
                            <p>These expansions will provide a broader range of housing packages and price points to first-time home buyers with a wider set of options to acquire their very own PHirst home, said CPGI president and CEO Marco Antonio.</p>
                            <p>He said with the new subsidiary, CPGI is committed to address the different needs of the market.</p>
                            <p>“The formation of the new subsidiary for the PHirst brand shows our deep commitment to cater to the diverse needs of first-time homebuyers in the Philippines. With this strategic initiative, we are poised to bring more quality homes to the market that are accessible to a wider range of Filipinos,” Antonio said.</p>
                            <p>Through  PHirst, the company is venturing into the socialized, economic, and mid-income residential markets and is also introducing its version of a mixed-use format.</p>
                            <p>It will develop mixed-use projects which will have commercial, retail, and institutional components.</p>
                            <p>As of end-December 2022, PHirst had built 5,908 units and turned over 3,835 homes to its buyers.</p>
                            <p>“We will continue to innovate and deliver top-quality homes and communities to meet their evolving needs, and the industry as a whole,” Antonio said.</p>
                            <p>The maiden mid-income development, PHirst Editions Batulao in Batangas is a 14.1-hectare project with a total sales value of P3.06 billion.</p>
                            <p>PHirst Sights Bay, meanwhile, is the socialized & economic housing development.</p>
                            <p>For the mixed-use township, PHirst is introducing the 36-hectare PHirst Centrale Hermosa in Bataan to be launched next month.</p>
                            <p>Centrale will launch various establishments, including PHirst Impressions and PHirst Editions for residential; PHirst Fairgrounds for commercial; and PHirst Boroughs for retail.</p>
                            <p>PHirst Centrale Hermosa aims to become the latest “it” town where individuals will reside, innovate, and flourish based on the township’s three fundamental principles: Dwell, Create & Thrive.</p>
                            <p>The township project will have 2,041 units valued at P6.74 billion.</p>
                            <p>Including these new product lines, the PHirst brand will now have 16 master planned communities covering 293 hectares of land with 19,869 units launched inventory valued at P34.43 billion.</p>
                            
                            <h5>- Iris Gonzales</h5>
                            <a href="https://www.philstar.com/business/2023/02/24/2247138/century-properties-ventures-new-housing-segments" target={'_blank'}>Read the original article here.</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExplorePage