var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.08567987949676947,
        "pitch": -0.008960570805484736,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.04103851833434469,
          "pitch": -0.03168010599549653,
          "rotation": 0,
          "target": "1-entrance-living-room"
        },
        {
          "yaw": -0.47984082505838543,
          "pitch": -0.028871396147515327,
          "rotation": 0,
          "target": "2-entrance-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-entrance-living-room",
      "name": "Entrance Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.6278399297520316,
        "pitch": 0.19797339306042616,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.211492236966782,
          "pitch": 0.08782516486647829,
          "rotation": 0,
          "target": "3-living-room"
        },
        {
          "yaw": -1.4310454313563774,
          "pitch": 0.04296438371217093,
          "rotation": 1.5707963267948966,
          "target": "4-bathroom"
        },
        {
          "yaw": 1.6727313222866105,
          "pitch": -0.013220424021024968,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 3.0650862681735074,
          "pitch": 0.0413321495733161,
          "rotation": 0,
          "target": "2-entrance-dining-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-entrance-dining-area",
      "name": "Entrance Dining Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.5327900044197591,
        "pitch": -0.022364370500383757,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.35668635832314877,
          "pitch": -0.01581762903084538,
          "rotation": 0,
          "target": "5-dining-and-kitchen-area"
        },
        {
          "yaw": 1.4579744149064444,
          "pitch": -0.008719728578142849,
          "rotation": 0.7853981633974483,
          "target": "6-upstairs"
        },
        {
          "yaw": 0.7506042291956057,
          "pitch": 0.12467596784838975,
          "rotation": 0,
          "target": "13-back-exterior"
        },
        {
          "yaw": 3.0185624144349914,
          "pitch": 0.020305213359188556,
          "rotation": 0,
          "target": "1-entrance-living-room"
        },
        {
          "yaw": -1.9740643803590583,
          "pitch": 0.040429481347613816,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.726570301291659,
        "pitch": 0.0013040680502349744,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.377703813540254,
          "pitch": 0.030811868497359285,
          "rotation": 7.853981633974483,
          "target": "4-bathroom"
        },
        {
          "yaw": 3.124342166853955,
          "pitch": 0.029771712566624586,
          "rotation": 0,
          "target": "2-entrance-dining-area"
        },
        {
          "yaw": 2.830370116078331,
          "pitch": 0.07032706491513352,
          "rotation": 0,
          "target": "1-entrance-living-room"
        },
        {
          "yaw": 2.6326160591314913,
          "pitch": -0.004907234809268246,
          "rotation": 4.71238898038469,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.1740133679375298,
        "pitch": 0.012082847187249612,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.834882847965293,
          "pitch": 0.005746933538590682,
          "rotation": 0,
          "target": "1-entrance-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-dining-and-kitchen-area",
      "name": "Dining and Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.724337884225534,
        "pitch": 0.05537202225876747,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.8528276203082594,
          "pitch": 0.039301044561014464,
          "rotation": 0,
          "target": "1-entrance-living-room"
        },
        {
          "yaw": -2.5926130116424275,
          "pitch": 0.05801521856196601,
          "rotation": 0,
          "target": "2-entrance-dining-area"
        },
        {
          "yaw": -2.3666811808388584,
          "pitch": -0.020262087665994244,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 2.6214125492329323,
          "pitch": -0.1475541364043984,
          "rotation": 0.7853981633974483,
          "target": "6-upstairs"
        },
        {
          "yaw": 1.5774062573250234,
          "pitch": 0.1771555490926655,
          "rotation": 0,
          "target": "13-back-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.7079097429176677,
        "pitch": -0.18585789921420037,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.01999117238796444,
          "pitch": -0.4174953039959508,
          "rotation": 0,
          "target": "7-hallway-2nd-floor"
        },
        {
          "yaw": 1.1058807276355296,
          "pitch": 0.07685383722627037,
          "rotation": 0,
          "target": "5-dining-and-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.6571126850104214,
        "pitch": 0.13126136707341374,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.6643352392744255,
          "pitch": 0.16807664437976477,
          "rotation": 0,
          "target": "11-master-bedroom"
        },
        {
          "yaw": 1.7277671416291955,
          "pitch": 0.9174442281850084,
          "rotation": 10.995574287564278,
          "target": "8-hallway-2nd-floor-2nd-view"
        },
        {
          "yaw": -1.3845732461146927,
          "pitch": 0.07477252280223645,
          "rotation": 1.5707963267948966,
          "target": "9-bedroom-1"
        },
        {
          "yaw": -1.9523118658366414,
          "pitch": 0.06664201880878551,
          "rotation": 4.71238898038469,
          "target": "10-bedroom-2"
        },
        {
          "yaw": 0.003521639813479638,
          "pitch": 0.8781564063542344,
          "rotation": 3.9269908169872414,
          "target": "6-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-hallway-2nd-floor-2nd-view",
      "name": "Hallway 2nd Floor 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -0.7901551440917984,
        "pitch": 0.11999459943647572,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.4003018882831206,
          "pitch": 0.04938278757155601,
          "rotation": 1.5707963267948966,
          "target": "9-bedroom-1"
        },
        {
          "yaw": -1.7297410147914078,
          "pitch": 0.045797683974321046,
          "rotation": 4.71238898038469,
          "target": "10-bedroom-2"
        },
        {
          "yaw": 1.4965420840316837,
          "pitch": 0.08811919559529535,
          "rotation": 0,
          "target": "11-master-bedroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.5236004443715849,
        "pitch": 0.11610589209112554,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.579369633862486,
          "pitch": 0.03870764177372443,
          "rotation": 0,
          "target": "10-bedroom-2"
        },
        {
          "yaw": -2.943182111739528,
          "pitch": 0.03643671391222725,
          "rotation": 4.71238898038469,
          "target": "7-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "10-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.6292211622861714,
        "pitch": 0.004787699662673006,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.8891795608122273,
          "pitch": 0.06298962012080978,
          "rotation": 0,
          "target": "9-bedroom-1"
        },
        {
          "yaw": 2.3034275116662295,
          "pitch": 0.06556102887605952,
          "rotation": 1.5707963267948966,
          "target": "7-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "11-master-bedroom",
      "name": "Master Bedroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 3.0310011359770863,
        "pitch": 0.018512328609451956,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.261421616061183,
          "pitch": 0.025739439035225686,
          "rotation": 1.5707963267948966,
          "target": "9-bedroom-1"
        },
        {
          "yaw": 2.0802282407253694,
          "pitch": 0.024487590782339197,
          "rotation": 4.71238898038469,
          "target": "10-bedroom-2"
        },
        {
          "yaw": 2.1549528649168908,
          "pitch": 0.4002321926645873,
          "rotation": 1.5707963267948966,
          "target": "7-hallway-2nd-floor"
        },
        {
          "yaw": -2.7990125063650257,
          "pitch": 0.06008242684800713,
          "rotation": 0,
          "target": "12-master-bedroom-2nd-view"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "12-master-bedroom-2nd-view",
      "name": "Master Bedroom 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.7787806904344521,
        "pitch": 0.0019860372882298805,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.28103724179892353,
          "pitch": 0.11608456257323496,
          "rotation": 0,
          "target": "11-master-bedroom"
        },
        {
          "yaw": 1.7095929445816642,
          "pitch": 0.11571753402538398,
          "rotation": 1.5707963267948966,
          "target": "7-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "13-back-exterior",
      "name": "Back Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.758540655193972,
        "pitch": 0.027639616243371634,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.9694288578575323,
          "pitch": 0.007432885941678791,
          "rotation": 0,
          "target": "2-entrance-dining-area"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-amani-tandem",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
