var APP_DATA = {
  "scenes": [
    {
      "id": "0-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.8434277165327728,
        "pitch": 0.057624094704408435,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.4824204448226794,
          "pitch": 0.07792336200893324,
          "rotation": 0,
          "target": "3-bathroom-1st-floor"
        },
        {
          "yaw": -2.1502424531290583,
          "pitch": 0.044429227226341794,
          "rotation": 0,
          "target": "4-back-exterior"
        },
        {
          "yaw": -1.8481664426162645,
          "pitch": 0.06392252390344311,
          "rotation": 0,
          "target": "1-dining-and-kitchen-area"
        },
        {
          "yaw": -2.7439649042737653,
          "pitch": -0.019812130734923272,
          "rotation": 5.497787143782138,
          "target": "2-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-dining-and-kitchen-area",
      "name": "Dining and Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.08864466592211073,
        "pitch": 0.2949348992698475,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.0732717862096592,
          "pitch": 0.16464761511173265,
          "rotation": 0,
          "target": "0-living-room"
        },
        {
          "yaw": 0.5723223829315138,
          "pitch": 0.08914813982063308,
          "rotation": 0,
          "target": "2-upstairs"
        },
        {
          "yaw": 0.9886499127754877,
          "pitch": -0.03039016096370517,
          "rotation": 6.283185307179586,
          "target": "3-bathroom-1st-floor"
        },
        {
          "yaw": 1.4358171862566467,
          "pitch": 0.20953686846225672,
          "rotation": 0,
          "target": "4-back-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.732307535641662,
        "pitch": 0.03990429802019868,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.1343716729912927,
          "pitch": -0.404701549964253,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": 1.3154843251073913,
          "pitch": 0.17838291199923795,
          "rotation": 4.71238898038469,
          "target": "3-bathroom-1st-floor"
        },
        {
          "yaw": 1.9920169875332876,
          "pitch": 0.22562950038937402,
          "rotation": 0,
          "target": "1-dining-and-kitchen-area"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-bathroom-1st-floor",
      "name": "Bathroom 1st Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.0137197640399584,
        "pitch": 0.03421133306730084,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.2522315812314524,
          "pitch": 0.11876188654380648,
          "rotation": 0,
          "target": "0-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-back-exterior",
      "name": "Back Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -1.4775360192875908,
        "pitch": 0.017882093260190857,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.3990242741820946,
          "pitch": -0.030796536644922767,
          "rotation": 0,
          "target": "0-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.8452254414392701,
        "pitch": 0.0665390947818949,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.21721513144199633,
          "pitch": 0.05642319942721308,
          "rotation": 0,
          "target": "7-bedroom-2"
        },
        {
          "yaw": -1.0412032222144134,
          "pitch": 0.04210396894704971,
          "rotation": 0,
          "target": "8-bedroom-3"
        },
        {
          "yaw": -1.6307710985516177,
          "pitch": 0.04484691494160131,
          "rotation": 0,
          "target": "9-bathroom-2nd-floor"
        },
        {
          "yaw": 1.0706873989197199,
          "pitch": 0.20357395321513216,
          "rotation": 0,
          "target": "6-bedroom-1"
        },
        {
          "yaw": -1.9670275573127238,
          "pitch": 0.8251796037880208,
          "rotation": 1.5707963267948966,
          "target": "2-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.8592217880588109,
        "pitch": -0.009828157607122634,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -3.086219267178624,
          "pitch": 0.03313875367391894,
          "rotation": 0,
          "target": "9-bathroom-2nd-floor"
        },
        {
          "yaw": -3.019921713165502,
          "pitch": 0.1400837861885318,
          "rotation": 1.5707963267948966,
          "target": "8-bedroom-3"
        },
        {
          "yaw": -2.9585733925225774,
          "pitch": 0.29206922869854424,
          "rotation": 1.5707963267948966,
          "target": "7-bedroom-2"
        },
        {
          "yaw": 2.989307325841599,
          "pitch": 0.1876010879009069,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.9858435863912138,
        "pitch": -0.08747236483544896,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.3774826279687105,
          "pitch": 0.09872145192341009,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-bedroom-3",
      "name": "Bedroom #3",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.409718630851593,
        "pitch": 0.06446023143564794,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.364773182142054,
          "pitch": 0.13201584530013832,
          "rotation": 0,
          "target": "5-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bathroom-2nd-floor",
      "name": "Bathroom 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.14659758994881145,
        "pitch": 0.12431666979664158,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 3.03275049396696,
          "pitch": 0.3328595041313065,
          "rotation": 1.5707963267948966,
          "target": "5-hallway-2nd-floor"
        },
        {
          "yaw": 2.9929920096450813,
          "pitch": -0.009812108150548227,
          "rotation": 0,
          "target": "6-bedroom-1"
        },
        {
          "yaw": 2.887977077104269,
          "pitch": 0.084469293792937,
          "rotation": 4.71238898038469,
          "target": "7-bedroom-2"
        },
        {
          "yaw": 2.830082688224076,
          "pitch": 0.20040573977071574,
          "rotation": 4.71238898038469,
          "target": "8-bedroom-3"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-Amani-Plus",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
