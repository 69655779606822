import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import '../css/about.css'
import LoadScreen from "../../others/loadScreen";
import BackButton from "../../others/backButton";

const AboutUs = () => {
    const aerialBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/aerial-bg-v5.jpg"
    const cardBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/card-bg.jpg"
    const cardLogo = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/house-card-logo.png"

    
    const section2Img = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/about-img-1.jpg"

    const cardGif1 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/gifs/Attractiveness-card-v3.gif"
    const cardGif2 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/gifs/active-card-v2.gif"
    const cardGif3 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/gifs/accessible-card-v1.gif"
    const cardGif4 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/gifs/alive-card-v1.gif"

    
    const [isLoading, setIsLoading] = useState(true) 

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return(
        <>
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content about-us" style={{ backgroundImage: 'url('+aerialBg+')'}}>
                <div className="section1">
                    <h4>About PHirst Sights</h4>
                    <p>
                        The journey of a lifetime begins here at PHirst Sights, where your vision of a brighter tomorrow comes to life. This is where you'll experience the magic of falling in love at first sight - with your new home and all the possibilities it brings.
                    </p>
                    <p>
                        It's time to take that first major step towards the future you've always imagined, and we're honored to be a part of it. So come, explore, and make yourself at home in the place where dreams become reality.
                    </p>
                    <h5>At PHirst Sights</h5>
                    <div className="cards-cont">
                        <Link to='/amani-series'>
                            <CardItem cardBg={cardBg} cardLogo={cardLogo} cardGif={cardGif1} cardID="card1" desc1="We bring you quality and" desc2="homes"/>
                        </Link>
                        <Link to='/amenities'>
                            <CardItem cardBg={cardBg} cardLogo={cardLogo} cardGif={cardGif2} cardID="card2" desc1="that enable you to enjoy a healthy and " desc2="lifestyle" />
                        </Link>
                        <Link to='/vicinity-map'>
                            <CardItem cardBg={cardBg} cardLogo={cardLogo} cardGif={cardGif3} cardID="card3" desc1="where essentials are" desc2="anytime, anywhere"/>
                        </Link>
                        <Link to='/marketing-tent'>
                            <CardItem cardBg={cardBg} cardLogo={cardLogo} cardGif={cardGif4} cardID="card4" desc1="and where the community feels good and" desc2=""/>
                        </Link>
                    </div>
                    <p className="subDesc">So come, get inspired, and make your dream come true. Get ready to fall in love at PHirst Sights!</p>
                </div>

                <div className="section2">
                    <div className="text-content">
                        <h4 className="bay-title">At PHirst Sights <span>Bay</span>,</h4>
                        <h5 className="bay-title">You can live that simple and quality life you dreamed of.</h5>
                        <div className="section-img" style={{ backgroundImage: 'url('+section2Img+')'}}>
                        </div>
                        <p>The first of many upcoming PHirst Sights developments can be found at the municipality of Bay, Laguna. Situated near the university town of Los Banos and near the foot of Mt. Makiling, enjoy the fresh breeze, the quiet life, and the peaceful environment that Bay has to offer. You can also visit Laguna de Bay, the country’s largest freshwater lake which the town is named after. Fall in love over and over again at PHirst Sights Bay! 
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

const CardItem = (props) => {
    const [isAnimatingCard, setIsAnimatingCard] = useState()


    useEffect(() => {
        let animText = document.getElementById(props.cardID)
        let animTimeout = setTimeout(() => {
            animText.src = props.cardGif
        }, 1000);

        return () =>  {
            clearTimeout(animTimeout)
        }
    }, [])

    useEffect(() => {
        let animText = document.getElementById(props.cardID)
        let cardReplayTimeout
        if (isAnimatingCard) {
            animText.src = ''
            animText.src = props.cardGif
    
            cardReplayTimeout = setTimeout(() => {
                setIsAnimatingCard(false)
            }, 4000);    
        }
        return () => {
            clearTimeout(cardReplayTimeout)
        }
    }, [isAnimatingCard])

    const replayGif = () => {
        
        let animText = document.getElementById(props.cardID)
        animText.src = ''
        
        let animTimeout = setTimeout(() => {
            animText.src = props.cardGif
        }, 1000);
    }

    return (
        <>
            <div className="card-item" style={{ backgroundImage: 'url('+props.cardBg+')'}} onMouseOver={ () => {
                setIsAnimatingCard(true)
            } }>
                <img className="card-logo" src={props.cardLogo} alt="" />
                <div className="anim-cont">
                    <p className="desc">{ props.desc1 }</p>
                    <img className="anim-text" id={props.cardID} alt="" />
                    <p className="desc">{ props.desc2 }</p>
                </div>
                <div className="card-overlay"></div>
            </div>
        </>
    )
}

export default AboutUs