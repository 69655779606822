import React, { useEffect, useState } from "react";

import LoadScreen from "../../others/loadScreen";

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../css/amani.css'
import { Carousel } from "react-responsive-carousel";

import { ContactFormTemplate } from "../../contact-us/components/contact-index";
import BackButton from "../../others/backButton";



const AmaniSeries = () => {

    const amaniMainImg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/amani-main-img-no-trees.png"
    const amaniMainImgTrees = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/amani-main-img-trees.png"

    const amaniMainImgV2 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/amani-main-bg-v2.jpg"
    const amaniMainImgV3 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-banner-img.jpg"

    
    const familyBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/family-amani-v3.jpg"
    const aerialBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/about/aerial-bg-v2.jpg"
    const texturedBg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/textured-bg.jpg"

    const amaniSeries = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-1.jpg"
    const amaniInner = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-4.jpg"
    const amaniEnd = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-2.jpg"
    const amaniExpanded = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-6.jpg"
    const amaniExt1 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-5.jpg"

    const amaniEndGf = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/AMANI_RENDERED GF_0213 2023.jpg"
    const amaniEnd2f = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/AMANI_RENDERED 2F_0213 2023.jpg"
    
    const amaniExpandedGf = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/AMANI EXPANDED_2F_3BR.jpg"
    const amaniExpanded2f = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/AMANI EXPANDED_2F_2BR_0214 2023.jpg"

    const amaniPlus = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/amani-plus-2F.jpg"
    const amaniPlusV2 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/amani-plus-2F-v2.jpg"
    
    const amaniTandemGf = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/amani-tandem-GF.jpg"
    const amaniTandem2F = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/floor-plan/amani-tandem-2F.jpg"

    const attractivenessGif = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/Attractiveness.gif"
    

    const swooshdesktop = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/Amani-swoosh-bg.png"
    const swooshMobile = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/Amani-swoosh-bg-mobile.png"

    const infoIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/info-icon.png"
    const infoIconAlt = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/info-icon-alt.png"

    const AreaIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Area.png"

    const KitchenIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Kitchen.png"
    const FurnitureIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Furniture.png"
    const BedroomIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Bedroom.png"
    const DiningIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Dining.png"
    const ExpandIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Expand.png"
    const BathroomIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Bathroom.png"
    const GateIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Gate.png"
    const CarIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/icons/Car.png"

    const swooshIcon = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/Amani-swoosh-v3.png"
    const amaniSeriesImgV2 = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/amani-series-v2.jpg"

    const latticePattern = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/lattice-pattern.png"

    const leftSidedToggle = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/left-sided-toggle.png"


    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ imgLoaded, setImgLoaded ] = useState({ swooshImg: false, houseImg: false, treeImg: false })

    const [ isExterior, setIsInterior ] = useState(true)

    const [ viewImage, setViewImage ] = useState({ show: false, imageLink: " "})

    const [ showSpecification1, setShowSpecification1 ] = useState(false)
    const [ showSpecification2, setShowSpecification2 ] = useState(false)
    const [ showSpecification3, setShowSpecification3 ] = useState(false)

    const [ is360ModalShown, state360Modal ] = useState(false)

    const [isLoading, setIsLoading] = useState(true) 

    useEffect(() => {
        let mainImg = document.getElementById("mainImg")
        let mainImgTrees = document.getElementById("mainImgTrees")
        
        let swoosh = document.getElementById('swoosh') 

        if (window.innerWidth < 578.98) {
            swoosh.src = swooshMobile
        } else {
            swoosh.src = swooshdesktop
        }
        setIsLoading(false)
        let textContTimeout
        // const section2Observer = new IntersectionObserver((entries) => {
        //     let textContent = document.querySelector('.section2 .text-content')
        //     if (entries[0].isIntersecting) {
        //         textContTimeout = setTimeout(() => {
        //             textContent.classList.add("show")
        //         }, 400);
        //     } else {
        //         textContent.classList.remove("show")
        //     }
        // }, {
        //     threshold: .5
        // })

        // let section2 = document.getElementById('section2')

        // section2Observer.observe(section2)

        return () => {
            clearTimeout(textContTimeout)
        }
    }, [])

    useEffect(() => {
        let headerTimeout
        let mainImgTimeout
        let animTimeout
        // if (imgLoaded.swooshImg && imgLoaded.houseImg && imgLoaded.treeImg) {
        //     setIsLoading(false)

        //     mainImgTimeout = setTimeout(() => {
        //         let mainImgCont = document.getElementById("mainImgCont")
        //         mainImgCont.classList.remove('hidden')
        //     }, 600);

        //     headerTimeout = setTimeout(() => {
        //         let headerText = document.querySelector("h2.main-header")
        //         headerText.classList.remove("hidden")
        //     }, 2100)

        //     let animText = document.getElementById('animText')
            
        //     animTimeout = setTimeout(() => {
        //         animText.src = attractivenessGif
        //     }, 4000);
        // }

        return () => {
            clearTimeout(headerTimeout)
            clearTimeout(mainImgTimeout)
            clearTimeout(animTimeout)
        }
    }, [imgLoaded])

    useEffect(() => {
        toggleUnitImages(isExterior)
    }, [isExterior])

    useEffect(() => {
    }, [viewImage])

    useEffect(() => {
        let infoBtn = document.getElementById('infoBtn1')
        let specs = document.getElementById('specs1') 
        if (showSpecification1) {
            specs.classList.add('show')
            infoBtn.src = infoIconAlt
        } else {
            specs.classList.remove('show')
            infoBtn.src = infoIcon
        }
    }, [ showSpecification1])

    useEffect(() => {
        let infoBtn = document.getElementById('infoBtn2')
        let specs = document.getElementById('specs2') 
        if (showSpecification2) {
            specs.classList.add('show')
            infoBtn.src = infoIconAlt
        } else {
            specs.classList.remove('show')
            infoBtn.src = infoIcon
        }
    }, [ showSpecification2])

    useEffect(() => {
        let infoBtn = document.getElementById('infoBtn3')
        let specs = document.getElementById('specs3') 
        if (showSpecification3) {
            specs.classList.add('show')
            infoBtn.src = infoIconAlt
        } else {
            specs.classList.remove('show')
            infoBtn.src = infoIcon
        }
    }, [ showSpecification3])



    const imgLinks = {
        links: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-1.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-2.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-3.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-4.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-5.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-6.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-7.jpg",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-8.jpg",
        ]
    }

    const imgLinksInterior = {
        links: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-1.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-2.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-3.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-4.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-5.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-6.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-7.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-8.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-9.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-10.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-11.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-12.JPG",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-interior/amani-interior-13.JPG",
        ]
    }

    const toggleUnitImages = (status) => {
        let unitCont = document.getElementById('unitCont')
        let scrollValue = unitCont.offsetWidth
        // let exteriorImages = document.querySelector('.unit-images.exterior')
        // let interiorImages = document.querySelector('.unit-images.interior')

        if (!status) {
            // exteriorImages.classList.toggle("show")
            // interiorImages.classList.toggle("show")

            unitCont.scrollLeft =+ scrollValue
        } else {
            // exteriorImages.classList.toggle("show")
            // interiorImages.classList.toggle("show")

            unitCont.scrollLeft = 0
        }
    }

    const showImage = (link) => {
        setViewImage((prevState) => ({
            ...prevState,
            show: true,
            imageLink: link
        }))
    }

    const hideImage = () => {
        setViewImage((prevState) => ({
            ...prevState,
            show: false,
            imageLink: ""
        }))
    }

    const onIconHover = (e) => {
        e.target.src = infoIconAlt
    }
    
    const onIconHoverOut = (e) => {
        e.target.src = infoIcon
    }

    const showSpecs = (val) => {
        switch (val) {
            case "specs1":
                setShowSpecification1((prevState) => !prevState)
            break;
            case "specs2":
                setShowSpecification2((prevState) => !prevState)
            break;
            case "specs3":
                setShowSpecification3((prevState) => !prevState)
            break;
        
            default:
                break;
        }
    }

    const toggleText = () => {
        let textCont = document.getElementById('mainTextContent')

        textCont.classList.toggle('hide')
    }

    const toggle360Modal = () => {
        state360Modal((prevState) => !prevState)
    }

    useEffect(() => {
        const modal = document.getElementById('modal360')
        if (is360ModalShown) {
            modal.style.display = "flex"
            setTimeout(() => {
                modal.classList.remove('hide')
            }, 100)
        } else {
            modal.classList.add('hide')
            setTimeout(() => {
                modal.style.display = "none"
            }, 400)
        }
    }, [is360ModalShown])

    return (
        <>
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content amani" style={{ backgroundImage: "url("+ aerialBg +")"}}>
                <main className="main" style={{ display: 'none' }}>
                    <h2 className="main-header hidden">
                        Amani<span>series</span>
                    </h2>
                    <img className="animated-text" src='' id="animText" alt="" />
                    <img className="swoosh-bg" src="" id="swoosh" alt="" draggable="false"
                        onLoad={ () => { 
                            setImgLoaded((prevState) => ({

                                ...prevState,
                                swooshImg: true
                            }))
                        }} 
                    />
                    <div className="main-img-container hidden" id="mainImgCont">
                        <img className="main-img" id="mainImg" src={ amaniMainImg } alt="" draggable="false"
                            onLoad={ () => { 
                                setImgLoaded((prevState) => ({
                                    ...prevState,
                                    houseImg: true
                                }))
                            }} 
                        />
                        <img className="main-img-trees" id="mainImgTrees" src={ amaniMainImgTrees } alt="" draggable="false"
                            onLoad={ () => { 
                                setImgLoaded((prevState) => ({
                                    ...prevState,
                                    treeImg: true
                                }))
                            }} 
                        />
                    </div>
                </main>

                <div className="main-v2">
                    <div className="text-content" style={{ backgroundImage: 'url('+ latticePattern +')'}} id="mainTextContent">
                        <div>
                            <div>
                                <h2 className="main-v2-header">Amani <span>series</span></h2>
                                <p>We bring you quality and <br /> <span>Attractive</span> homes.</p>
                                <button className="modal-360-btn" onClick={ toggle360Modal }>Check out Amani 360&deg; Views</button>      
                            </div>
                            <img className="mobile-img" src={ amaniSeriesImgV2 } alt="" />
                            <small>Scroll down to see more</small>
                            <img className="text-swoosh" src={ swooshIcon } alt="" />
                        </div>
                        <img className="cont-toggle" src={ leftSidedToggle } alt="" onClick={ toggleText } />
                    </div>
                    <div className="img-content"  style={{ backgroundImage: 'url(' + amaniMainImgV3 + ')'}}>

                    </div>
                    <div className="color-overlay"></div>
                </div>
                
                <div className="section2" style={{ backgroundImage: "url("+ familyBg +")"}} id='section2'>
                    <div className="text-content show">
                        <h4>Finally, a home and community you can fall in love with over and over again!</h4>
                    </div>
                </div>

                <div className="section3">
                    <div className="color-overlay"></div>
                    <div className="section-content">
                        <div className="text-cont">
                            <h4>Meet the <span>Amani</span> Series</h4>
                            <p>
                                PHirst Sights’ Amani Series townhouse design offers a unique blend of community, privacy, and convenience, making it a great choice for those looking for a comfortable and low-maintenance housing option that you and your family can truly enjoy. It’s the perfect option for those looking to get the first home of their dreams.
                            </p>
                            <div className="details">
                                <div className="detail-item">
                                    <img src={ AreaIcon } alt="" />
                                    <p>Typical Lot Area (End): 55 sqm </p>
                                </div>
                                <div className="detail-item">
                                    <img src={ AreaIcon } alt="" />
                                    <p>Typical Lot Area (Inner): 40 sqm </p>
                                </div>
                                <div className="detail-item">
                                    <img src={ AreaIcon } alt="" />
                                    <p>Floor Area: 36 sqm</p>
                                </div>

                                <div className="detail-item">
                                    <img src={ BedroomIcon } alt="" />
                                    <p>2 Bedrooms</p>
                                </div>
                                <div className="detail-item">
                                    <img src={ BathroomIcon } alt="" />
                                    <p>1 Toilet and Bath</p>
                                </div>
                                <div className="detail-item">
                                    <img src={ GateIcon } alt="" />
                                    <p>Gate and Fence</p>
                                </div>
                                <div className="detail-item">
                                    <img src={ CarIcon } alt="" />
                                    <p>Provision for 1 Carport<br />(Compact Car)</p>
                                </div>
                                <div className="detail-item">
                                    <img src={ ExpandIcon } alt="" />
                                    <p>Expandable</p>
                                </div>
                            </div>
                            <small>*See below other unit specifications of Amani Series.</small>
                        </div>
                        <div className="section-carousel">
                            <Carousel swipeable="false">
                                <div>
                                    <img src={ amaniSeries } alt="" />
                                </div>
                                <div>
                                    <img src={ amaniEnd } alt="" />
                                </div>
                                <div>
                                    <img src={ amaniInner } alt="" />
                                </div>
                                <div>
                                    <img src={ amaniExt1 } alt="" />
                                </div>
                                <div>
                                    <img src={ amaniExpanded } alt="" />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>

                <div className="section4" style={{ backgroundImage: "url("+ texturedBg +")"}}>  
                    <div className="unit-gallery">
                        <div className="unit-control">
                            <button className={"exterior-btn" + ( isExterior ? " active" : "" )} onClick={ () => {setIsInterior(true)}}>Exterior</button>
                            <button className={"interior-btn" + ( !isExterior ? " active" : "" )} onClick={ () => {setIsInterior(false)}}>Interior</button>
                        </div>
                        <div className="unit-cont" id="unitCont">
                            <div className="unit-images exterior" id="exteriorImages">
                                { imgLinks.links.map((link, index) => {
                                return <ImageGalleryItem link={ link } key={index} onClick={ () => {showImage(link) }}/>
                                })}
                            </div>
                            <div className="unit-images interior" id="interiorImages">
                                { imgLinksInterior.links.map((link, index) => {
                                return <ImageGalleryItem link={ link } key={index} onClick={ () => {showImage(link) }}/>
                                }) }
                            </div>
                        </div>
                    </div>
                    { viewImage.show ? <ImageViewer imgLink={ viewImage.imageLink } closeModal={ () => { hideImage() }}/> : <></> }
                    
                </div>

                <div className="section5">
                    <h2>Floor Plan</h2>
                    <div className="color-overlay"></div>
                    <div className="floor-plan-cont">
                        <div className="floor-item">
                            <h4>Amani End & Inner Units
                                <img className="infoIcon" id="infoBtn1" src={ infoIcon } alt="" onMouseEnter={ (e) => { onIconHover(e) } } onMouseLeave={ (e) => { onIconHoverOut(e) }} onClick={ () => { showSpecs("specs1")}}/>
                            </h4>
                            <div className="img-cont">
                                <div>
                                    <img className="floor-plan" src={ amaniEndGf } alt="" />
                                    <h5>Ground Floor</h5>
                                </div>
                                <div>
                                    <img className="floor-plan" src={ amaniEnd2f } alt="" />
                                    <h5>2nd Floor</h5>
                                </div>
                                
                                <div className="specs-cont" id="specs1">
                                    <h5>Specifications</h5>
                                    <div className="item-list">
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Typical Lot Area (End): <span>55 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Typical Lot Area (Inner): <span>40 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Floor Area: <span>36 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BedroomIcon } alt="" />
                                            <p>2 Bedrooms</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BathroomIcon } alt="" />
                                            <p>1 Toilet & Bath</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ GateIcon } alt="" />
                                            <p>Gate and Fence</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ CarIcon } alt="" />
                                            <p>Provision for 1 Carport</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ ExpandIcon } alt="" />
                                            <p>Expandable</p>
                                        </div>
                                    </div>
                                    <div className="close-specs">
                                        <span onClick={ () => { setShowSpecification1(false)}}>Close</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="floor-item">
                            <h4>Amani Plus
                                <img className="infoIcon" id="infoBtn2" src={ infoIcon } alt="" onMouseEnter={ (e) => { onIconHover(e) } } onMouseLeave={ (e) => { onIconHoverOut(e) }} onClick={ () => { showSpecs("specs2")}}/>
                            </h4>
                            <div className="img-cont">
                                <div>
                                    <img className="floor-plan" src={ amaniPlus } alt="" />
                                    <h5>2nd Floor (Version 1)</h5>
                                </div>
                                <div>
                                    <img className="floor-plan" src={ amaniPlusV2 } alt="" />
                                    <h5>2nd Floor (Version 2)</h5>
                                </div>
                                <div className="specs-cont" id="specs2">
                                    <h5>Specifications</h5>
                                    <div className="item-list">
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Typical Lot Area: <span>40 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Floor Area: <span>41 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BedroomIcon } alt="" />
                                            <p>2-3 Bedrooms</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BathroomIcon } alt="" />
                                            <p>2 Toilet & Bath</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ GateIcon } alt="" />
                                            <p>Gate and Fence</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ CarIcon } alt="" />
                                            <p>Provision for 1 Carport</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ ExpandIcon } alt="" />
                                            <p>Expandable</p>
                                        </div>
                                    </div>
                                    <div className="close-specs">
                                        <span onClick={ () => { setShowSpecification2(false)}}>Close</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="floor-item">
                            <h4>Amani Tandem
                                <img className="infoIcon" id="infoBtn3" src={ infoIcon } alt="" onMouseEnter={ (e) => { onIconHover(e) } } onMouseLeave={ (e) => { onIconHoverOut(e) }} onClick={ () => { showSpecs("specs3")}}/>
                            </h4>
                            <div className="img-cont">
                                <div>
                                    <img className="floor-plan" src={ amaniTandemGf } alt="" />
                                    <h5>Ground Floor</h5>
                                </div>
                                <div>
                                    <img className="floor-plan" src={ amaniTandem2F } alt="" />
                                    <h5>2nd Floor</h5>
                                </div>
                                <div className="specs-cont" id="specs3">
                                    <h5>Specifications</h5>
                                    <div className="item-list">
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Typical Lot Area: <span>95 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ AreaIcon } alt="" />
                                            <p>Floor Area: <span>72 sqm</span></p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BedroomIcon } alt="" />
                                            <p>3 Bedrooms</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ BathroomIcon } alt="" />
                                            <p>2 Toilet & Bath</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ GateIcon } alt="" />
                                            <p>Gate and Fence</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ CarIcon } alt="" />
                                            <p>Provision for 1 Carport</p>
                                        </div>
                                        <div className="specs-item">
                                            <img src={ ExpandIcon } alt="" />
                                            <p>Expandable</p>
                                        </div>
                                    </div>
                                    <div className="close-specs">
                                        <span onClick={ () => { setShowSpecification3(false)}}>Close</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                <ContactFormTemplate />
            </div>
            <div className="modal-360" id="modal360">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4>Amani Series 360&deg; Views</h4>
                        <i className="bi bi-x-circle" onClick={ toggle360Modal }></i>
                    </div>
                    <div className="modal-body">
                        <div className="links">
                            <a href='/amani-bare-unit/360' target="_top">
                                Amani Bare Unit 360&deg;
                            </a>
                            <a href='/amani-end/360' target="_top">
                                Amani End 360&deg;
                            </a>
                            <a href='/amani-inner/360' target="_top">
                                Amani Inner 360&deg;
                            </a>
                            <a href='/amani-plus/360' target="_top">
                                Amani Plus 360&deg;
                            </a>
                            <a href='/amani-tandem/360' target="_top">
                                Amani Tandem 360&deg;
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ImageGalleryItem = (props) => {
    return (
        <>        
            <div className="img-item" style={{ backgroundImage: "url(" + props.link + ")" }} onClick={props.onClick}>
                <div className="color-overlay"></div>
            </div>
        </>
    )
}

const ImageViewer = (props) => {
    return (
        <>
            <div className="modal-overlay"></div>
            <div className="unit-modal">
                <img className="modal-close" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/others/menu-close-white.png" alt="" onClick={ props.closeModal }/>
                <img className="modal-img" src={ props.imgLink }  alt="" />
            </div>
        </>
    )
}

export default AmaniSeries