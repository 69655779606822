import React, { useEffect, useRef, useState } from "react";

import '../css/map.css'

import Loadscreen from '../../others/loadScreen.js'
import BackButton from "../../others/backButton";

const VicinityMap = () => {
    
    const texturedBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/textured-bg-3.jpg'
    const aerialBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/aerial-bg-v3-white.jpg'

    const lattice = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/explore/buying-guide/explore-lattice-bg.jpg'

    const [isLoading, setIsLoading] = useState(true) 

    useEffect(() => {
        setIsLoading(false)
        mobileText()

        window.addEventListener('resize', mobileText)

        return () => {
            window.removeEventListener('resize', mobileText)
        }
    }, [])

    const mobileText = () => {
        
        if (window.innerWidth <= 1024) {
            let textContent = document.getElementById('mapTextContent')
            textContent.addEventListener('click', (e) => {
                textContent.style.display = 'none'
            })
        }

    }
    return (
        <>
            <Loadscreen/>
            <BackButton/>
            <div className="content vicinity-map" >
                <div className="main">
                    <div className="text-content" id="mapTextContent" style={{ backgroundImage: 'url(' + lattice + ')'}}>
                        <div>
                            <h2>Vicinity Map</h2>
                            <p>Let’s help you picture the community of your dreams. Find the perfect spot for your home and see nearby establishments that you can enjoy.</p>

                            <div className="instructions">
                                <small>Click and drag to navigate the map.</small>
                                <small>Toggle the buttons at the bottom of the map to see various establishments.</small>
                                <br />

                                <small className="close-reminder">Tap to close.</small>
                            </div>
                        </div>
                    </div>
                    <div className="map-content">
                        <Map />
                    </div>

                </div>
            </div>
        </>
    )
}


const Map = () => {

    const map = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/vicinity-map-v2.jpg'

    const zoomInIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/zoom-in-v2.png'
    const zoomOutIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/zoom-out-v2.png'

    
    const zoomInIconDisabled = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/zoom-in-v2-disabled.png'
    const zoomOutIconDisabled = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/zoom-out-v2-disabled.png'

    const schoolIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icon-school.png' 
    const churchIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icon-church.png' 
    const hospitalIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icon-hospital.png' 
    const supermarketIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icon-market.png' 

    const mapIcons = {
        school: [
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-school.png',
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-school-alt.png'
        ],
        church: [
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-church.png',
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-church-alt.png'
        ],
        hospital: [
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-hospital.png',
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-hospital-alt.png'
        ],
        market: [
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-market.png',
            'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/icons/icon-circle-market-alt.png'
        ]

    }

    const mapEstablishments = {
        school: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-schools-v2.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-schools-mobile-v2.png",
        ],
        church: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-church-v2.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-church-mobile-v2.png",
        ],
        hospital: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-hospital-v2.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-hospital-mobile-v2.png",
        ],
        supermarket: [
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-supermarket-v2.png",
            "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/vicinity-map/map-supermarket-mobile-v2.png",
        ],
    }

    const [ isSchoolShown, setIsSchool ] = useState(false)
    const [ isChurchShown, setIsChurch ] = useState(false)
    const [ isHospitalShown, setIsHospital ] = useState(false)
    const [ isSupermarketShown, setIsSupermarket ] = useState(false)

    const mapTop = useRef(0)
    const mapLeft = useRef(0)
    const mapX = useRef(0)
    const mapY = useRef(0)

    
    const mobileMapTop = useRef(0)
    const mobileMapLeft = useRef(0)
    const mobileMapX = useRef(0)
    const mobileMapY = useRef(0)

    const zoomCounter = useRef(0)
    const isFullView = useRef(false)

    const windowWidth = useRef(0)

    useEffect(() => {
        centerMap()
        centerMapMobile()
        windowWidth.current = window.innerWidth

        window.addEventListener('resize', getWindowWidth)
        window.addEventListener('resize', resetMap)

        return () => {
            window.removeEventListener('resize', getWindowWidth)
            window.removeEventListener('resize', resetMap)
        }
    }, [])
    
    const getWindowWidth = () => {
        windowWidth.current = window.innerWidth
    }
    const resetMap = () => {
        let mapImgs = document.querySelectorAll('.map img') 
        let resetValue

        if (windowWidth.current >= 1680.98) {
            resetValue = 1280
        } else if ( windowWidth.current <= 1680.98 && windowWidth.current >= 1440.98) {
            resetValue = 1024
        } else if (windowWidth.current <= 1440.98 && windowWidth.current >= 1024) {
            resetValue = 800
        } else if (windowWidth.current <= 1024 && windowWidth.current >= 768) {
            resetValue = 2160
        } else if (windowWidth.current <= 576) {
            resetValue = 1440
        }


        mapImgs.forEach((img) => {
            img.style.maxWidth = resetValue + 'px'
        })

        zoomCounter.current = 0

        let zoomOutBtn = document.getElementById('zoomOut')
        let zoomInBtn = document.getElementById('zoomIn')

        zoomOutBtn.setAttribute('src', zoomOutIconDisabled)
        zoomInBtn.setAttribute('src', zoomInIcon)
    }

    const centerMap = () => {
        let mapContainer = document.getElementById('map')

        let valX = (mapContainer.scrollWidth - mapContainer.offsetWidth) / 2
        let valY = (mapContainer.scrollHeight - mapContainer.offsetHeight) / 2
        mapContainer.scrollLeft = valX
        mapContainer.scrollTop = valY
    }

    const centerMapMobile = () => {
        let mapContainer = document.getElementById('mapMobile')

        let valX = (mapContainer.scrollWidth - mapContainer.offsetWidth) / 2
        let valY = (mapContainer.scrollHeight - mapContainer.offsetHeight) / 2
        mapContainer.scrollLeft = valX
        mapContainer.scrollTop = valY
    }

    // Desktop 
    const moveMap = (e) => {    
        let mapContainer = document.getElementById('map')
        mapContainer.style.cursor = 'grabbing'

        mapTop.current = mapContainer.scrollTop
        mapLeft.current = mapContainer.scrollLeft

        mapY.current = e.clientY
        mapX.current = e.clientX

        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('mouseup', mouseUp)
    }

    const mouseMove = (e) => {
        let mapContainer = document.getElementById('map')

        const dx = e.clientX - mapX.current
        const dy = e.clientY - mapY.current

        mapContainer.scrollLeft = mapLeft.current - dx
        mapContainer.scrollTop = mapTop.current - dy
    }

    const mouseUp = () => {
        let mapContainer = document.getElementById('map')
        mapContainer.style.cursor = 'auto'
        document.removeEventListener('mousemove', mouseMove)
        document.removeEventListener('mouseup', mouseUp)
    }

    // Mobile 
    const touchMap = (e) => {
        let contentDiv = document.querySelector('div.content')
        contentDiv.style.touchAction = 'none'
        // contentDiv.style.overflowY = 'hidden'

        let mapContainerMobile = document.getElementById('mapMobile')

        mobileMapTop.current = mapContainerMobile.scrollTop
        mobileMapLeft.current = mapContainerMobile.scrollLeft
        
        mobileMapX.current = e.targetTouches[0].clientX
        mobileMapY.current = e.targetTouches[0].clientY

        document.addEventListener('touchmove', touchMove)
        document.addEventListener('touchend', touchEnd)
    }

    const touchMove = (e) => {
        let mapContainerMobile = document.getElementById('mapMobile')

        const dx = e.changedTouches[0].clientX - mobileMapX.current
        const dy = e.changedTouches[0].clientY - mobileMapY.current

        mapContainerMobile.scrollLeft = mobileMapLeft.current - dx
        mapContainerMobile.scrollTop = mobileMapTop.current - dy

    }

    const touchEnd = () => {
        document.removeEventListener('touchmove', touchMove)
        document.removeEventListener('touchend', touchEnd)
        
        let contentDiv = document.querySelector('div.content')
        contentDiv.style.touchAction = 'auto'
        // contentDiv.style.overflowY = 'auto'
    }


    const zoom = (val) => {
        let mapContainer = document.querySelector('.map-container.desktop .map')
        let mapMainImg = document.querySelector('img.map-main')
        let mapImgs = document.querySelectorAll('.map img')

        let imgCurrentWidth = mapMainImg.offsetWidth    
        let zoomAmount = imgCurrentWidth * 0.25

        if (val === 'in' && zoomCounter.current < 2) {
            mapImgs.forEach((img) => {
                img.style.maxWidth = (img.clientWidth + 320) + 'px'
            })
            zoomCounter.current += 1
        } else if (val === 'out' && zoomCounter.current > 0) {
            mapImgs.forEach((img) => {
                let val = img.clientWidth - 320
                if (val >= mapContainer.clientWidth) {
                    img.style.maxWidth = val + 'px'
                } else {
                    img.style.maxWidth = mapContainer.clientWidth
                }
            })
            zoomCounter.current -= 1
        }

        
        let zoomOutBtn = document.getElementById('zoomOut')
        let zoomInBtn = document.getElementById('zoomIn')

        if (zoomCounter.current == 0) {
            zoomOutBtn.setAttribute('src', zoomOutIconDisabled)
            zoomOutBtn.classList.add('disabled')
        } else {
            zoomOutBtn.setAttribute('src', zoomOutIcon)
            zoomOutBtn.classList.remove('disabled')
        }

        if (zoomCounter.current == 2) {
            zoomInBtn.setAttribute('src', zoomInIconDisabled)
            zoomInBtn.classList.add('disabled')
        } else {
            zoomInBtn.setAttribute('src', zoomInIcon)
            zoomInBtn.classList.remove('disabled')
        }
    }

    const toggleEstablishments = (val) => {
        switch (val) {
            case "school":
                if (isSchoolShown) {
                    setIsSchool((prevState) => !prevState)
                } else {
                    setIsSchool(true)
                    setIsChurch(false)
                    setIsHospital(false)
                    setIsSupermarket(false)
                }
            break;
            case "church":
                if (isChurchShown) {
                    setIsChurch((prevState) => !prevState)
                } else {
                    setIsSchool(false)
                    setIsChurch(true)
                    setIsHospital(false)
                    setIsSupermarket(false)
                }
            break;
            case "hospital":
                if (isHospitalShown) {
                    setIsHospital((prevState) => !prevState)
                } else {
                    setIsSchool(false)
                    setIsChurch(false)
                    setIsHospital(true)
                    setIsSupermarket(false)
                }
            break;
            case "supermarket":
                if (isSupermarketShown) {
                    setIsSupermarket((prevState) => !prevState)
                } else {
                    setIsSchool(false)
                    setIsChurch(false)
                    setIsHospital(false)
                    setIsSupermarket(true)
                }
            break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="establishment-toggler desktop">
                <h4 className="toggler-title">Vicinity Map</h4>
                <button className={(isSchoolShown ? 'active' : '')} onClick={ () => { toggleEstablishments("school") } }>School</button>
                <button className={(isChurchShown ? 'active' : '')} onClick={ () => { toggleEstablishments("church") } }>Church</button>
                <button className={(isHospitalShown ? 'active' : '')} onClick={ () => { toggleEstablishments("hospital") } }>Hospital</button>
                <button className={(isSupermarketShown ? 'active' : '')} onClick={ () => { toggleEstablishments("supermarket") } }>Market</button>
            </div>
            <div className="map-container desktop">
                <div className="map-control">
                    <img className="zoom-btn" src={ zoomInIcon } onClick={ () => { zoom('in') } } alt="" id="zoomIn"/>
                    <img className="zoom-btn disabled" src={ zoomOutIconDisabled } onClick={ () => { zoom('out') } } alt="" id="zoomOut"/>
                </div>
                <div className="establishment-toggler-v2 mobile">
                    <img className={"toggler " + ( isSchoolShown ? 'active' : '')} src={ schoolIcon } alt="" onClick={ () => { toggleEstablishments("school") } }/>
                    <img className={"toggler " + ( isChurchShown ? 'active' : '')} src={ churchIcon } alt="" onClick={ () => { toggleEstablishments("church") } }/>
                    <img className={"toggler " + ( isHospitalShown ? 'active' : '')} src={ hospitalIcon } alt="" onClick={ () => { toggleEstablishments("hospital") } }/>
                    <img className={"toggler " + ( isSupermarketShown ? 'active' : '')} src={ supermarketIcon } alt="" onClick={ () => { toggleEstablishments("supermarket") } }/>
                </div>
                <div className="map" id="map" onMouseDown={ (e) => { moveMap(e) } }>
                    <img className="map-main" src={ map } alt="" draggable="false" />
                    <img className={"establishments " + (isSchoolShown ? "show" : "")} src={ mapEstablishments.school[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isChurchShown ? "show" : "")} src={ mapEstablishments.church[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isHospitalShown ? "show" : "")} src={ mapEstablishments.hospital[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isSupermarketShown ? "show" : "")} src={ mapEstablishments.supermarket[0] } alt=""  draggable="false" />
                </div>
            </div>
            
            <div className="map-container mobile">
                {/* <div className="map-control">
                    <img className="zoom-btn" src={ zoomInIcon } onClick={ () => { zoom('in') } } alt="" />
                    <img className="zoom-btn" src={ zoomOutIcon } onClick={ () => { zoom('out') } } alt="" />
                </div> */}
                <div className="establishment-toggler-v2">
                    <img className="toggler" src={ isSchoolShown ? mapIcons.school[1] : mapIcons.school[0] } alt="" onClick={ () => { toggleEstablishments("school") } }/>
                    <img className="toggler" src={ isChurchShown ? mapIcons.church[1] : mapIcons.church[0] } alt="" onClick={ () => { toggleEstablishments("church") } }/>
                    <img className="toggler" src={ isHospitalShown ? mapIcons.hospital[1] : mapIcons.hospital[0] } alt="" onClick={ () => { toggleEstablishments("hospital") } }/>
                    <img className="toggler " src={ isSupermarketShown ? mapIcons.market[1] : mapIcons.market[0] } alt="" onClick={ () => { toggleEstablishments("supermarket") } }/>
                </div>
                <div className="map" id="mapMobile" onTouchStart={ (e) => { touchMap(e) } }>
                    <img className="map-main" src={ map } alt="" draggable="false" />
                    <img className={"establishments " + (isSchoolShown ? "show" : "")} src={ mapEstablishments.school[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isChurchShown ? "show" : "")} src={ mapEstablishments.church[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isHospitalShown ? "show" : "")} src={ mapEstablishments.hospital[0] } alt=""  draggable="false" />
                    <img className={"establishments " + (isSupermarketShown ? "show" : "")} src={ mapEstablishments.supermarket[0] } alt=""  draggable="false" />
                </div>
            </div>
        </>
        
    )
}

export default VicinityMap