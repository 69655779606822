import React, { useState, useEffect } from "react";

import './css/tent.css'
import { Link } from "react-router-dom";

import LoadScreen from "../others/loadScreen";
import BackButton from "../others/backButton";

import BookTripping from "../others/bookATripping";

const MarketingTent = () => {
    
    const tentBg = ''

    const sightsLogo = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/phirst-sights-logo.png'

    
    const carouselImg1 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/carousel-img-1.jpg'
    const carouselImg2 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/carousel-img-2.jpg'
    const carouselImg3 = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/carousel-img-3.jpg'
    
    const aerialBg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/marketing-tent/aerial-bg-v3-white.jpg'
    
    const [isLoading, setIsLoading] = useState(true) 
    const [ isBooking, setBooking ] = useState(false)

    useEffect(() => {
        window.addEventListener('resize', resetScroll)

        const mainTextContentObserver = new IntersectionObserver((entries) => {
            let cd = 1000
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.classList.add('show')
                    }, cd);
                    cd = cd + 1200
                }
            })
        }, {
            threshold: 0.5
        })

        const mainTextList = document.querySelectorAll('.main .text-content a > p') 

        mainTextList.forEach((item) => {
            mainTextContentObserver.observe(item)
        })



        const textContentObserver = new IntersectionObserver((entries) => {
            entries.forEach((item) => {
                item.target.classList.toggle('show', item.isIntersecting)
            })
        }, {
            threshold: 0.5,
            rootMargin: '100px'
        })

        const carouselItem = document.querySelectorAll('div.carousel-item')

        carouselItem.forEach(element => {
            textContentObserver.observe(element)
        });


        setIsLoading(false)
        
        return () => {
            carouselItem.forEach(element => {
                textContentObserver.unobserve(element)
            });
            window.removeEventListener('resize', resetScroll)
        }

    }, [])
    
    useEffect(() => {
        let bookmodal = document.getElementById('bookTrippingModal')
        if (isBooking) {
            bookmodal.classList.add("show")    
        } else {
            bookmodal.classList.remove("show")
        }

        let bookContent = document.getElementById('bookContent')
        if (bookContent.scrollTop != 0) bookContent.scrollTop = 0
    }, [isBooking])
    
    const resetScroll = () => {
        const carouselCont = document.getElementById('carouselCont')
        carouselCont.scrollLeft = 0
    }

    const scrollLeft = () => {
        const carouselCont = document.getElementById('carouselCont')
        carouselCont.scrollLeft = carouselCont.scrollLeft - carouselCont.offsetWidth
    }


    const scrollRight = () => {
        const carouselCont = document.getElementById('carouselCont')
        carouselCont.scrollLeft = carouselCont.scrollLeft + carouselCont.offsetWidth
    }

    return (
        <>  
            <LoadScreen isLoading={isLoading}/>
            <BackButton />
            <div className="content tent" style={{ backgroundImage: 'url('+ aerialBg +')'}}>
                <main className="main">
                    <img className="sights-logo" src={ sightsLogo } alt="" />
                    <div className="text-content">
                        <Link to=''>
                            <p>We bring you quality and <span>Attractive</span> homes</p>
                        </Link>
                        <Link to=''>
                            <p>that enables you to enjoy a healthy and <span>Active</span> lifestyle</p>
                        </Link>
                        <Link to=''>
                            <p>where essentials are <span>Accessible</span> anytime, anywhere</p>
                        </Link>
                        <Link to=''>
                            <p>and where community feels good and <span>Alive</span></p>
                        </Link>
                    </div>
                </main>

                <div className="section2 carousel" id="carouselCont">
                    <div className="carousel-item" style={{ backgroundImage: 'url('+ carouselImg1 +')' }}>
                        <div className="text-content">
                            <p>Hey there! Have you ever walked into a home and felt like you were meant to be there?</p>
                            <a href="#" onClick={ scrollRight }>Next</a>
                        </div>
                        <div className="color-overlay"></div>
                        {/* <img src={ carouselImg1 } alt="" /> */}
                    </div>
                    <div className="carousel-item" style={{ backgroundImage: 'url('+ carouselImg2 +')' }}>
                        <div className="text-content">
                            <p>That's what we want you to experience at PHirst Sights!
                            </p>
                            <p>
                                We believe that a home is more than just a physical space. It's where you create a haven for yourself and your family, where you can grow and make memories that will last a lifetime.
                            </p>
                            <a className="back-btn" href="#" onClick={ scrollLeft }>Back</a>
                            <a href="#" onClick={ scrollRight }>Next</a>
                        </div>
                        <div className="color-overlay"></div>
                        {/* <img src={ carouselImg2 } alt="" /> */}
                    </div>
                    <div className="carousel-item" style={{ backgroundImage: 'url('+ carouselImg3 +')' }}>
                        <div className="text-content">
                            <p>At PHirst Sights, we help you find a home where you can relax and create a life you love. So come and take a look at what we have to offer. Who knows, you might just fall in love at first sight!</p>
                            <a className="back-btn" href="#" onClick={ scrollLeft }>Back</a>
                        </div>
                        <div className="color-overlay"></div>
                        {/* <img src={ carouselImg3 } alt="" /> */}
                    </div>
                </div>

                <div className="section3 front-desk">
                    <div className="front-desk-content">
                        <h4>Front Desk</h4>
                        <div className="front-desk-btns">
                            <a href="#" onClick={ () => {setBooking((prevState) => !prevState)} }>Book A Tripping</a>
                            <Link to='/vicinity-map'>
                                Vicinity Map
                            </Link>
                            <Link to='/sights-exit/360'>
                                PHirst Sights 360&deg;
                            </Link>
                            <Link to='/contact-us'>
                                Contact Us
                            </Link>
                            <Link to='/explore-sights'>
                                Buying Guide
                            </Link>
                            <Link to='/about'>
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
                <BookTripping toggleBook={() => {setBooking((prevState) => !prevState)}} />
            </div>
        </>
    )
}

export default MarketingTent