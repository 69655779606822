import React from "react";
import { Link } from "react-router-dom";

import './back-button.css'


const BackButton = () => {

    const houseIcon = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/landing/house-icon-v2.png'

    return (
        <>
            <div className="back-btn-cont">
                <Link to='/'>
                    <img src={ houseIcon } alt="" />
                </Link>
            </div>
        </>
    )
}

export default BackButton