var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.2193503450772036,
        "pitch": -0.12393856721117835,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.5233205863333685,
          "pitch": -0.04324919019400397,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 3.0226623444846226,
        "pitch": 0.09036865611195388,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 2.9880610868301973,
          "pitch": 0.03686146154872816,
          "rotation": 0,
          "target": "2-bathroom"
        },
        {
          "yaw": 2.583059947793698,
          "pitch": -0.06929214334931721,
          "rotation": 5.497787143782138,
          "target": "3-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.03860207956869388,
        "pitch": 0.05605631280426948,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.1603981557640317,
          "pitch": 0.0012568765123432968,
          "rotation": 1.5707963267948966,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.9596117773761552,
        "pitch": -0.09934028487251645,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.012482828412572644,
          "pitch": -0.4345230176662245,
          "rotation": 4.71238898038469,
          "target": "4-hallway-2nd-floor"
        },
        {
          "yaw": -0.8434828968654529,
          "pitch": 0.20012225691873375,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -1.7029783076167213,
          "pitch": 0.1502996318201273,
          "rotation": 4.71238898038469,
          "target": "2-bathroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 0.09424769855259996,
        "pitch": -0.02193151600856247,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.937811117462994,
          "pitch": 0.9759755165054393,
          "rotation": 0,
          "target": "3-upstairs"
        },
        {
          "yaw": 0.3875363380008281,
          "pitch": 0.03621764459152388,
          "rotation": 1.5707963267948966,
          "target": "5-room-1"
        },
        {
          "yaw": -0.38948823181376646,
          "pitch": 0.03210492466351589,
          "rotation": 4.71238898038469,
          "target": "6-room-2"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-room-1",
      "name": "Room #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -2.6445860152536014,
        "pitch": -0.07876608372054861,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -1.6006682539642938,
          "pitch": 0.011152094488547704,
          "rotation": 1.5707963267948966,
          "target": "4-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-room-2",
      "name": "Room #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": -1.960413488489685,
        "pitch": -0.025235115026276844,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.789784884474214,
          "pitch": 0.08556262230106171,
          "rotation": 17.27875959474387,
          "target": "4-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-amari-inner",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA
