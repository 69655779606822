var APP_DATA = {
  "scenes": [
    {
      "id": "0-entrance-exterior",
      "name": "Entrance Exterior",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.5250295856194143,
        "pitch": -0.019532076791826114,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.049083884383686,
          "pitch": -0.05933630205203855,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "1-living-room",
      "name": "Living Room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.3697353516141355,
        "pitch": 0.050871354302847394,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.006961292529410912,
          "pitch": 0.0284266970885092,
          "rotation": 0,
          "target": "4-back-exterior"
        },
        {
          "yaw": 0.3722874284940314,
          "pitch": 0.12468081034917589,
          "rotation": 0,
          "target": "3-bathroom"
        },
        {
          "yaw": 0.7204561886371668,
          "pitch": 0.19746937676184118,
          "rotation": 0.7853981633974483,
          "target": "5-upstairs"
        },
        {
          "yaw": -0.3799627494280138,
          "pitch": 0.05662100218317967,
          "rotation": 0,
          "target": "2-dining-and-kitchen-area"
        },
        {
          "yaw": 3.045433043457302,
          "pitch": 0.020531321060122565,
          "rotation": 0,
          "target": "0-entrance-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "2-dining-and-kitchen-area",
      "name": "Dining and Kitchen Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 1376,
      "initialViewParameters": {
        "yaw": 2.1977587968546315,
        "pitch": 0.12117838188455288,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.318866268797482,
          "pitch": -0.09494363398403394,
          "rotation": 0.7853981633974483,
          "target": "5-upstairs"
        },
        {
          "yaw": 2.6052558041638054,
          "pitch": 0.03492180256659516,
          "rotation": 0,
          "target": "0-entrance-exterior"
        },
        {
          "yaw": 2.9207258397820555,
          "pitch": 0.23115281491350714,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": -0.5167901878946086,
          "pitch": 0.1275775738987317,
          "rotation": 0,
          "target": "4-back-exterior"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "3-bathroom",
      "name": "Bathroom",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.5718796943950037,
        "pitch": 0.009601129012350995,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.9674339701984138,
          "pitch": 0.06540952218878004,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "4-back-exterior",
      "name": "Service Area",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -2.26454306814961,
        "pitch": 0.038233551972146174,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -2.332192872097078,
          "pitch": 0.03657516964199381,
          "rotation": 0,
          "target": "1-living-room"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "5-upstairs",
      "name": "Upstairs",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 0.5045588201219964,
        "pitch": -0.10707653161383313,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": -0.01441990892448608,
          "pitch": -0.4352087349587137,
          "rotation": 1.5707963267948966,
          "target": "6-hallway-2nd-floor"
        },
        {
          "yaw": 1.1986645130021163,
          "pitch": 0.21822962984825445,
          "rotation": 0,
          "target": "2-dining-and-kitchen-area"
        },
        {
          "yaw": 0.6424319459986307,
          "pitch": 0.1589611961618438,
          "rotation": 0,
          "target": "1-living-room"
        },
        {
          "yaw": 1.664996726149985,
          "pitch": 0.2393538042941632,
          "rotation": 1.5707963267948966,
          "target": "3-bathroom"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "6-hallway-2nd-floor",
      "name": "Hallway 2nd Floor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.06949583748849264,
        "pitch": -0.011611510762298494,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.1962494003104176,
          "pitch": -0.04525584218239942,
          "rotation": 1.5707963267948966,
          "target": "7-bedroom-1"
        },
        {
          "yaw": -0.90962494003104176,
          "pitch": -0.04525584218239942,
          "rotation": 1.5707963267948966,
          "target": "9-bedroom-2"
        },
        {
          "yaw": 1.9104515947795724,
          "pitch": 0.9879083803089053,
          "rotation": 0,
          "target": "5-upstairs"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "7-bedroom-1",
      "name": "Bedroom #1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": 2.1224945579196133,
        "pitch": 0.07436178389467507,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 1.227367903709398,
          "pitch": 0.28234162758134573,
          "rotation": 0,
          "target": "8-bedroom-1---2nd-view"
        },
        {
          "yaw": -0.1619058149945385,
          "pitch": 0.09118853108640579,
          "rotation": 4.71238898038469,
          "target": "6-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "8-bedroom-1---2nd-view",
      "name": "Bedroom #1 - 2nd View",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "yaw": -0.6901984206083256,
        "pitch": 0.02322160518553673,
        "fov": 1.4461899673881597
      },
      "linkHotspots": [
        {
          "yaw": 0.09801089699154097,
          "pitch": 0.08741593748007936,
          "rotation": 7.853981633974483,
          "target": "6-hallway-2nd-floor"
        },
        {
          "yaw": -1.1373715316014188,
          "pitch": 0.08335241832853768,
          "rotation": 0,
          "target": "7-bedroom-1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "9-bedroom-2",
      "name": "Bedroom #2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        },
        {
          "tileSize": 512,
          "size": 4096
        }
      ],
      "faceSize": 2752,
      "initialViewParameters": {
        "pitch": 0.32322160518553673,
        "yaw": 0,
        "fov": 1.5707963267948966
      },
      "linkHotspots": [
        {
          "yaw": 1.64801089699154097,
          "pitch": 0.18741593748007936,
          "rotation": 7.853981633974483,
          "target": "6-hallway-2nd-floor"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "360-amari-end",
  "settings": {
    "mouseViewMode": "drag",
    "autorotateEnabled": false,
    "fullscreenButton": true,
    "viewControlButtons": true
  }
};

export default APP_DATA