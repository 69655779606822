export const coords = [
    {
        id: "amenities",
        title: "Amenities",
        shape: "poly",
        name: "amenities",
        // coords: [1249,553,1290,533,1255,499,1280,474,1290,479,1315,455,1316,448,1311,444,1333,423,1409,455,1460,400,1289,334,1234,383,1297,409,1251,453,1264,412,1255,412,1258,405,1217,387,1205,384,1192,353,1174,352,1172,355,1176,364,1171,376,1146,374,1127,414,1126,401,1090,396,1095,394,1041,349,1009,362,1001,356,890,400,900,411,862,427,910,477,984,446,1035,501,1092,508,1094,520,1142,525,1144,518,1220,527],
        coords: [755,511,790,552,862,529,896,565,1057,590,1180,516,1250,537,1303,501,1159,454,1102,480,1093,490,1076,488,1058,452,1040,450,1040,463,1039,466,1025,467,1000,495,973,491,934,462],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "marketingTent",
        title: "Marketing Tent",
        shape: "poly",
        name: "marketing tent",
        // coords: [907,720,1041,935,1044,937,1057,930,1069,952,1389,760,1477,854,1602,772,1610,752,1645,728,1561,655,1622,619,1619,618,1627,594,1657,576,1481,441,1229,563,1254,588,1254,594,1249,596,1222,567],
        coords: [702,703,769,873,788,865,809,910,1166,766,1232,840,1374,777,1376,733,1399,722,1359,687,1414,662,1417,619,1433,613,1433,609,1298,513],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "amaniSeries",
        title: "Amani Series",
        shape: "poly",
        name: "amani series",
        // coords: [598,305,565,315,645,433,1117,247,1117,244,1102,239,1101,226,1040,181,1032,140,1034,138,1034,134,1041,127,1041,124,995,101,934,120,925,116,921,117,922,122,865,140,859,137,854,139,855,143,794,161,788,157,785,159,787,163,722,184,716,180,713,181,714,188,645,209,638,203,635,205,638,211,550,238,550,243,582,283,586,282],
        coords: [558,508,1014,392,1012,369,964,344,955,293,958,290,927,277,875,288,864,284,862,284,863,289,812,300,802,295,799,296,801,301,746,312,738,307,736,307,738,312,676,325,669,320,667,321,668,327,604,339,597,334,594,334,596,341,509,359,523,380,532,404,513,408],
        fillColor: "rgba(75, 128, 169, .7)",
    },
    {
        id: "aloraSeries",
        title: "Alora Series",
        shape: "poly",
        name: "alora series",
        // coords: [1356,161,1351,238,1320,260,1588,354,1609,324,1644,288,1660,230,1674,220,1676,215,1601,193,1602,185,1598,184,1590,188,1533,173,1534,164,1530,164,1521,168,1469,154,1469,146,1467,146,1457,150,1395,130,1343,152,1344,156],
        coords: [1203,403,1427,470,1492,424,1499,370,1508,367,1508,360,1445,346,1447,339,1442,339,1433,341,1391,333,1390,327,1387,327,1375,329,1335,320,1336,315,1333,315,1320,318,1277,307,1225,313,1227,318,1236,321,1236,389],
        fillColor: "rgba(75, 128, 169, .7)",
    }
]
