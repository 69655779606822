import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

const AmaniImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/amani-series/unit-exterior/amani-exterior-1.jpg'
const AloraImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/alora-series/unit-exterior/alora-exterior-7.jpg'
const PropertiesImg = "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/landing/marketing-tent-modal.jpg"
const AmenitiesImg = 'https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/landing/amenity-nodes-modal.jpg'



const AdditionalDetails = props => {
    const [ isBalcony, setBalcony] = useState(false)

    return (
        <div className="additional-details">
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-floor-area.png" alt="" loading="lazy"/>
                <h4>{ props.floorArea }</h4>
                <small>Floor area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-lot-area.png" alt="" loading="lazy"/>
                <h4>{ props.lotArea}</h4>
                <small style={{textAlign: "center"}}>Typical<br />Lot area</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-shower2.png" alt="" loading="lazy"/>
                <h4>{ props.bathroom }</h4>
                <small>Bathrooms</small>
            </div>
            <div className="detail">
                <img src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-bed.png" alt="" loading="lazy"/>
                <h4>{ props.bedroom }</h4>
                <small>Bedrooms</small>
            </div>
            <div className="detail">
                <img src={ isBalcony 
                    ? "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-balcony.png" 
                    : "https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-editions/images/units-page/details-carport.png"} alt="" loading="lazy"/>
                { isBalcony ?
                    <>
                        <h4>{ props.balcony }</h4>
                        <small>Balcony</small>
                    </>
                    :
                    <>
                        <h4>{ props.carport }</h4>
                        <small>Carport</small>
                    </>
                }
            </div>
        </div>
    )
}

const Amenities = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 400), top: (props.posY - 40) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + AmenitiesImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                    {/* <h4>Amenities</h4> */}
                </div>
                <h4>Amenity Nodes</h4>
                <p className="amenities-desc">Enhance your quality of living in a community that offers a range of amenities to support an active lifestyle. Discover a healthy and energetic way of life at PHirst Sights!</p>
                <div className="modal-btn">
                    {/* <p>Coming Soon!</p> */}
                    <Link to="/amenities" ><button>Read More</button></Link>
                </div>
            </div>
        </div>
    )
}

const MarketingTent = (props) => {
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX + 20), top: (props.posY - 160) }}>
        <div className="modal-body">
            <div className="modal-img" style={{ backgroundImage: "url(" + PropertiesImg + ")"}}>
                <a href="#" onClick={ props.hideModal }>
                    <i className="bi-x"></i>
                </a>
            </div>
            <h4>Marketing Tent</h4>
            <p>Get ready to fall in love at PHirst Sights!</p>
            <div className="modal-btn">
                {/* <p>Coming Soon!</p> */}
                <Link to="/marketing-tent" ><button>See More</button></Link>
            </div>
        </div>
    </div>
    )
}

const AmaniUnit = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" }
    return (
        <div className={ "custom-modal2 " + (props.timestamp) } style={{ left: (props.posX - 400), top: (props.posY - 100) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + AmaniImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="amani">Amani <span>series</span></h4>
                <p>Experience the joy of a simple and comfortable home, where you can relish the good things in life. PHirst Sights presents the Amani Series, your ticket to a peaceful and personal abode.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/amani-series" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

const AloraUnit = (props) => {
    const details = { id:"amaniSeries", lotArea: "88 sqm", floorArea: "54 sqm", bathroom: "2", bedroom: "3", carport: "1" }
    return (
        <div className={ "custom-modal2 alora " + (props.timestamp) } style={{ left: (props.posX + 60), top: (props.posY - 72) }}>
            <div className="modal-body">
                <div className="modal-img" style={{ backgroundImage: "url(" + AloraImg + ")"}}>
                    <a href="#" onClick={ props.hideModal }>
                        <i className="bi-x"></i>
                    </a>
                </div>
                <h4 className="alora">Alora <span>series</span></h4>
                <p>Discover the perfect blend of community and comfort with PHirst Sights’ Alora Series. Our beautifully designed rowhouses cater to singles and small families alike, providing high-quality loft-style living.</p>
                {/* { <AdditionalDetails {...details} /> } */}
                <div className="modal-btn">
                    <Link to="/alora-series" ><button>See More</button></Link>
                </div>
            </div>
        </div>
    )
}

export { Amenities, MarketingTent, AmaniUnit, AloraUnit }