import React, { useEffect, useState } from "react";

import { Amenities, MarketingTent, AmaniUnit, AloraUnit } from "./content";

// import { Data } from '../assets/files/modalContents'

const Modal = (props) => {


    useEffect(() => {
    }, [])


    const Render = (title) => {
        switch (title) {
            case "Amenities":
                return <Amenities {...props} />
                break;
            case "Marketing Tent":
                return <MarketingTent {...props} />
                break;
            case "Amani Series":
                return <AmaniUnit {...props} />
                break;
            case "Alora Series":
                return <AloraUnit {...props} />
                break;
            // default:
            //     break;
        }
    }

    return (
        <>
            { Render(props.title) }
            <div className="dark-screen" onClick={ props.hideModal }></div>
        </>
    )
    
}


export default Modal
